import { useState } from "react";
import {
    Button,
    Flex, Heading, Image
} from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
import { Link as Routerlink, } from "react-router-dom";
import logo from '../../../assets/general/PsyScribeLogo.svg';
import AddPatientButton from "./AddPatientButton";
import InvitesButton from "./InvitesButton";
import ProfileButton from "./ProfileButton";
import { lavenderStyle, Theme } from "../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";



function TopNavigationRow() {
    const [theme] = useState<Theme>(lavenderStyle)
const navigate = useNavigate()


    return (
        


            <Flex pl='20px' pr='20px' pb='5px' mb='10px' width="100%" mt="2" zIndex={'overlay'}  borderBottom='1px solid' borderBottomColor={theme.fourthColor} >
                <Image ml='2' width='150px' height='50px' src={logo} alt="My Logo"   style={{ cursor: 'pointer' }} onClick={() => navigate('/homeScreen') }        _hover={{  transform: 'scale(1.02)', transition: 'all 0.2s ease-in-out', }}/>
                <Flex width="100%" justifyContent={"flex-end"} columnGap='1' >
       
                <Button variant={'outline'} size='sm' mt='9px' mr='10px' leftIcon={<FontAwesomeIcon icon={faPlus} />} as={Routerlink} to='/manageSubscriptionScreen' colorScheme={theme.colorScheme}> Abonnement</Button>

                    <ProfileButton />

                </Flex>
            </Flex>


  
    );
}



export default TopNavigationRow