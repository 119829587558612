import { useContext, useEffect, useState } from 'react'
import { User } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
import FirebaseInterface from '../services/firebase';
import { useNavigate } from "react-router-dom";
import { Flex, Spinner } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../styles/styles';
import { collection, limit, onSnapshot, query, where } from "firebase/firestore";
import { UserProfileContext } from '../contexts/userProfileContextProvider';
import { TherapistProfile,  UserState } from '../types/types';

const ProtectedAuthAndProfileRoute = ({ children }: { children: any }) => {
    const [firebase] = useState<FirebaseInterface>(new FirebaseInterface());
    const [user, isUserProfileProviderCheckingCurrentUser] = useAuthState(firebase.auth);
    const navigate = useNavigate();
    const [theme] = useState<Theme>(lavenderStyle)
    const { therapistProfile: userProfile, setTherapistProfile: setUserProfile, profileFetchingComplete, currentUser } = useContext(UserProfileContext);
    const path = window.location.pathname;
    const [userState, setUserState] = useState<UserState>('loading')

    const userProfileIsComplete = (therapistProfile: TherapistProfile) => {
        if (therapistProfile.name === "not_complete") {
            return false;
        }
      
        return true;
    }

    const getUserState = (isUserProfileProviderCheckingCurrentUser: boolean, isFetchingFirestoreUserProfileComplete: boolean, currentUser: User | null, therapistProfile: TherapistProfile) => {
        if (isUserProfileProviderCheckingCurrentUser) {
            return 'loading'
        }
        else {
            if (currentUser) {
                if (currentUser.emailVerified) {
                    if (isFetchingFirestoreUserProfileComplete) {
                        if (userProfileIsComplete(therapistProfile)) {

                            return 'complete'
                        }
                        else {
                            return 'profileIncomplete'
                        }
                    }
                    else {
                        return 'loading'
                    }
                }
                else {
                    return 'emailNotVerified'
                }
            }
            else {
                return 'signedOut'
            }
        }
    }

    useEffect(() => {
        const newUserState = getUserState(isUserProfileProviderCheckingCurrentUser, profileFetchingComplete, currentUser, userProfile);
        switch (newUserState) {
            case 'loading':
                break
            case 'emailNotVerified':
                navigate('/emailNotVerifiedScreen')
                break
            case 'signedOut':
                navigate('/');
                break
            case 'profileIncomplete':
                navigate('/nameTherapistSettingScreen')
                break
            case 'complete':
                if(path === '/userDataFetchinScreen') {
                    navigate('/homeScreen')
                }
                break
            default:
                //navigate('/');
        }

        // !!! IMPORTANT THIS NEEDS TO BE SET LAST SO THAT WHEN WE GO AWAY FROM LOADING STATE
        /// WE DONT SHOW THE  <div> {children} </div> BEFORE WE NAVIGATE AWAY
        if (userState !== newUserState) {
            setUserState(newUserState);
        }
    

    // DONT INCLUDE USER PROFILE IN DEPENDENCIES WE ONLY WANT TO FETCH IT ONCE WE REFRESH
    // SOMETIMES IN THE APP WE UPDATE THE USER PROFILE AND WE DONT WANT TO REDIRECT THAN
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserProfileProviderCheckingCurrentUser, profileFetchingComplete, currentUser]);

    // subscriptions
    useEffect(() => {
        if (user) {
            const collectionRef = collection(firebase.db, "customers", user.uid, 'subscriptions');
            const q = query(
                collectionRef,
                where('status', 'in', ['trialing', 'active']),
                limit(1)
            )
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setUserProfile(
                        {
                            ...userProfile,
                            subscriptionId: doc.data().role,
                        }
                    )
                });
            });
            return () => {
                unsubscribe();
            };

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    if (userState === 'loading') {
        return <Flex flexDir="column" width="100%" height={"99vh"} justifyContent="center" alignItems="center"><Spinner color={theme.thirdColor} size="xl" /> </Flex>;
    }
    return (
        <div>
            {children}
        </div>
    )
}

export default ProtectedAuthAndProfileRoute