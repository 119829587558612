import {useState } from "react";
import {
    IconButton, Menu, MenuButton, MenuItem, MenuList, useBreakpointValue, Text
} from '@chakra-ui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faQuestion, faUser } from "@fortawesome/free-solid-svg-icons";
import { lavenderStyle, Theme } from "../../../styles/styles";
import { Link as Routerlink, useLocation } from "react-router-dom";
import {  AddIcon, SettingsIcon } from "@chakra-ui/icons";
import { t } from "i18next";



function ProfileButton() {

    const [theme] = useState<Theme>(lavenderStyle)
    const buttonSize = useBreakpointValue({ base: "md", sm: "lg", md:"lg"}); // Use useBreakpointValue hook to set different widths based on screen size
    const location = useLocation();

    

    return (
  
        <IconButton aria-label="Go back"
        variant="ghost"
        colorScheme={theme.colorScheme}
        icon={<FontAwesomeIcon icon={faUser}  size='lg' />}
        size={buttonSize}
        as={Routerlink} to='/accountScreen'
        borderRadius={theme.borderRadius} > </IconButton>

       


    );
}

export default ProfileButton
;




