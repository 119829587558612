import { useContext, useEffect, useState } from "react";
import {
    Divider,
    Flex, Heading, IconButton, Spinner, Text
} from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import { useNavigate, useParams } from "react-router-dom";
import {  Mood, Patient } from "../../types/types";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import TopNavigationRow from "../../components/sharedComponents/topNavigationRow/TopNavigationRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import Calendar from "react-calendar";
import './Calendar.css';
import MoodtrackerIcon from "../../components/moodtracker/MoodTrackerIcon";
import ChatDoesNotExistAlert from "../../components/alerts/ChatDoesNotExistAlert";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";



function LinkedPatientCalendarScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const navigate = useNavigate()
    const { therapistProfile, currentUser } = useContext(UserProfileContext);
    const firebaseInterface = useContext(FirebaseContext);
    const [currentPatients] = useState<Patient[]>(therapistProfile.patients)
    const { patientId } = useParams<string>();
    const [patient, setPatient] = useState<Patient | null>(null)

   
    const [isFetchingMoods, setIsFetchingMoods] = useState<boolean>(true)
    const [dayIdToMoodsMap, setDayIdToMoodsMap] = useState<Map<string, Mood[]>>(new Map<string, Mood[]>())
    const [showChatDoesNotExistAlert, setShowChatDoesNotExistAlert] = useState<boolean>(false)

    function getMoodTrackingIcon({ activeStartDate, date, view }: any) {

        if (view === 'month') {
            const id = firebaseInterface.dateToFirebaseDateId(date)
            const moods = dayIdToMoodsMap.get(id)

            if (moods) {
                // calculate average mood

                let sum = 0;

                moods.forEach((mood) => {

                    sum += mood.moodRating
                })

                const averageMood = sum / moods.length

                const avg = Number((averageMood).toFixed(1))


                return <MoodtrackerIcon moodRating={avg} />
            }
            //random number betweeen 0 and 10
            //const randomNumber = Math.floor(Math.random() * 10);
            return <MoodtrackerIcon moodRating={0} />
        }
    }

    async function clickOnDate(value: any, event: any) {
        if (!currentUser) {
            return;
        }
        const firebaseDayId = firebaseInterface.dateToFirebaseDateId(value)
        const exists = dayIdToMoodsMap.has(firebaseDayId)
        if (exists) {
            navigate(`/linkedPatientInsightsScreen/${patientId}/${firebaseInterface.dateToFirebaseDateId(value)}`);
        }
        else {
            setShowChatDoesNotExistAlert(true)
        }
    }
    useEffect(() => {
        if (!patientId) {
            return
        }
        // check if patient is already in currentPatients
        const patient = currentPatients.find((patient) => patient.userId === patientId)
        if (patient) {
            setPatient(patient)
            return
        }
    }, [patientId, currentPatients])

 



    useEffect(() => {
        if (!patientId) {
            return;
        }
        setIsFetchingMoods(true)
        firebaseInterface.fetchAllMoods(patientId).then((moods) => {
            setDayIdToMoodsMap(moods)

            setIsFetchingMoods(false)

        })
    }, [currentUser, firebaseInterface, patientId])




    const handleSettingsClick = () => {
        navigate(`/linkedPatientSettingsScreen/${patientId}`);
    };


    return (
        <Flex flexDirection='column' maxHeight="97svh" height='100vh' width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            <TopNavigationRow />
            
            <Flex width="90%" justifyContent={"flex-start"}  >
                <PreviousScreenButton previousScreen={"/homeScreen"} />
            </Flex>


            <Flex width="100%" justifyContent={"flex-start"} mt="-30px"   >



            </Flex>
            <Flex width={"100%"} justifyContent="center" alignItems={'center'}>
                <Heading size='lg' marginLeft="5px">
                    {patient?.name}
                </Heading>
                <IconButton ml='20px' aria-label="Go back"
                    variant="solid"
                    icon={<FontAwesomeIcon icon={faGear} size='lg' />}

                    size={'md'}
                    borderRadius={theme.borderRadius} onClick={handleSettingsClick}> </IconButton>
            </Flex>


            <Divider mb='15px' mt='15px' maxWidth={'600px'}></Divider>

            <Flex maxWidth={'1000px'} justifyContent='center' alignItems={'center'} mb='40px' >
                <Text width='80%' mt='20px' fontStyle={'italic'} > Dit is een overzicht van de dagen waarop uw patiënt actief was. De nummers zijn de gemiddelde stemming van uw patiënt. Klik op een bepaalde dag voor stemming en gespreksinzichten.</Text>

            </Flex>

            {showChatDoesNotExistAlert && <ChatDoesNotExistAlert onClose={() => setShowChatDoesNotExistAlert(false)} />}

            {isFetchingMoods ? <Spinner size="xl" color={theme.thirdColor} mt='20px' mb='20px' /> :
                <Flex flexDir={'column'} maxWidth='350px'>
                    <Calendar tileContent={getMoodTrackingIcon} onChange={clickOnDate} />
                </Flex>
            }











        </Flex>
    );
}



export default LinkedPatientCalendarScreen
