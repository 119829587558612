import { SubscriptionPlan } from "../../types/types";



export const trialPlan : SubscriptionPlan = {
    id: "therapist_trial",
    displayName: "Proef Plan",
    limitTotalPatients: 3,
    stripeProductIdEUR: "not purchasable",
    price: 0,
};

export const smallPlan : SubscriptionPlan = {
    id: "therapist_small",
    displayName: "Starter Plan",
    limitTotalPatients: 10,
    stripeProductIdEUR: "price_1NuYdpEC7qjZh8n0q5O7Aicv",
    price: 49.99,
};


export const mediumPlan: SubscriptionPlan  =  {
    id: "therapist_medium",
    displayName: "Essential Plan",
    limitTotalPatients: 25,
    stripeProductIdEUR: "price_1NuYd2EC7qjZh8n0DbYvCv3E",
    price: 124.99,
}

export const largePlan: SubscriptionPlan  =  {
    id: "therapist_large",
    displayName: "Elite Plan",
    limitTotalPatients: 50,
    stripeProductIdEUR: "price_1NsmILEC7qjZh8n0zjXChQnE",
    price: 249.99,
}


