import { useContext, useState } from "react";
import {
    Box,
    Button, Flex, FormControl, Heading, Input, Text
} from '@chakra-ui/react'

import { Link as Routerlink, useLocation } from "react-router-dom";
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { FirebaseContext } from "../../contexts/firebaseProvider";
function NameTherapistSettingScreen() {

    const [isError, setIsError] = useState(false);
    const [theme] = useState<Theme>(lavenderStyle)
    const { therapistProfile, setTherapistProfile, currentUser } = useContext(UserProfileContext);
    const [userName, setUsername] = useState(therapistProfile.name && therapistProfile.name !== "not_complete" ? therapistProfile.name : "");

    const [placeHolderText] = useState(t('NameUserSettingScreen_input_placeholder'))
    const location = useLocation();
    const [isFirstTime] = useState(!(location.state && location.state.from === "/accountScreen"));
    const firebaseInterface = useContext(FirebaseContext);
    const [nameTooLongError, setNameTooLongError] = useState(false);


    function updateTherapistName(userName: string) {
        therapistProfile.name = userName;
        if (currentUser) {
            firebaseInterface.updateTherapistName(currentUser.uid, userName);
        }
        else {
            throw new Error("User not logged in");
        }
    }

    const handleNameChange = (event: any) => {
        const input = event.target.value;
        if (input.length <= 60) {
            setUsername(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))
            setIsError(false);
            setNameTooLongError(false);
        } else {
            setNameTooLongError(true);
        }
    };


    return <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >

        <Flex width={"100%"} flexDir={"column"} >
            <Flex width={"100%"} justifyContent="center">
                <Heading size='lg' paddingTop='5px' marginLeft="5px" mt='20px'>
                    Wat is uw naam?
                </Heading>
            </Flex>
        </Flex>

        <Flex flexDir="column" alignItems="center" height={"100%"} width="100%" >
            <Flex flexDirection={"column"} justifyContent="flex-start" width="100%" maxWidth={"600px"} marginTop="20px" marginBottom="40px">
                <Text fontStyle={"italic"} fontSize="md" textAlign="center" marginBottom="20px">
                    Uw naam wordt gebruikt om uw patiënten u te laten herkennen wanneer u ze een uitnodiging stuurt.

                </Text>
                <>
                    <FormControl id="username">

                        <Flex flexDir="column" width="100%" alignItems={"center"}>
                         
                                <Text fontWeight={'bold'} mb='10px'>Voornaam + Achternaam</Text>

                                <Input width={"400px"} placeholder={"Voer uw voor naam in"}
                                    _placeholder={{ opacity: 0.6, color: 'inherit' }} type="text" value={userName} onChange={handleNameChange} isRequired />
                                {!nameTooLongError ? (
                                    <Flex></Flex>
                                ) : (
                                    <Text fontSize="sm" >  Deze naam is te lang</Text>)}
                                {!isError ? (
                                    <Flex></Flex>
                                ) : (
                                    <Text fontSize="sm" > U bent vergeten uw naam in te vullen </Text>)}
                        </Flex>
                        

                    </FormControl>
                </>

            </Flex>

            <Flex justifyContent='center' height="100%" width="100%" flexDirection='row' >
                {!(userName === '') ? (
                    <Button as={Routerlink} to={isFirstTime ? "/homeScreen" : "/accountScreen"} state={{ from: location.pathname }} colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} onClick={() => updateTherapistName(userName)}> {isFirstTime ? "Ga verder" : "Update"}</Button>
                ) : (
                    <Button colorScheme={theme.colorScheme} variant='solid' size='lg' fontSize="lg" borderRadius={theme.borderRadius} onClick={() => setIsError(true)}> {isFirstTime ? "Ga verder" : "Update"}</Button>
                )}
            </Flex>
        </Flex>


    </Flex >
}


export default NameTherapistSettingScreen