import { useContext, useEffect, useState } from "react";
import {
    Button,
    Divider,
    Flex, Heading, Spinner, Text
} from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { SubscriptionPlan } from "../../types/types";
import { Link as Routerlink, useLocation, useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { largePlan, mediumPlan, smallPlan, trialPlan } from "../../data/subscriptions/subscriptionPlans";
import ProgressBar from "../../components/alerts/ProgressBar";
import SubscriptionPlanSelector from "../../components/subscriptionScreens/SubscriptionPlanSelector";
import TopNavigationRow from "../../components/sharedComponents/topNavigationRow/TopNavigationRow";

function ManageSubscriptionScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    // const [userSubscriptionPlan, setUserSubscriptionPlan] = useState<SubscriptionID>()
    const { therapistProfile, currentUser } = useContext(UserProfileContext);

    const location = useLocation();
    const [stripeLoading, setStripeLoading] = useState<boolean>(false)
    const [nextPaymentDate, setNextPaymentDate] = useState<string>("")
    const [isCanceled, setIsCanceled] = useState<boolean>(false)
    const firebaseInterface = useContext(FirebaseContext);
    const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState<SubscriptionPlan | null>(null)

    const navigate = useNavigate();
    const [numberActiveInvites, setNumberActiveInvites] = useState<number>(0)

    useEffect(() => {
        if (!currentUser) {
            return
        }
        firebaseInterface.fetchInvitations(currentUser.uid).then((invites) => {

            let nValidInvites = 0
            invites.forEach((invite) => {
                if (invite.valid) {
                    nValidInvites += 1
                }
            })
            setNumberActiveInvites(nValidInvites)
        }
        )


    }, [currentUser, firebaseInterface])

    useEffect(() => {
        if (currentUser) {
            firebaseInterface.getSubscriptionInfo(currentUser.uid).then((subscriptionInfo) => {
                if (subscriptionInfo) {
                    switch (subscriptionInfo.role) {
                        case 'therapist_small':
                            setCurrentSubscriptionPlan(smallPlan)
                            break;
                        case 'therapist_medium':
                            setCurrentSubscriptionPlan(mediumPlan)
                            break;
                        case 'therapist_large':
                            setCurrentSubscriptionPlan(largePlan)
                            break;

                    }

                    const firebaseTimestamp = subscriptionInfo.current_period_end; // Replace with your Firebase timestamp
                    const timestampInMillis = firebaseTimestamp.seconds * 1000; // Convert seconds to milliseconds
                    const date = new Date(timestampInMillis); // Create a Date object using the milliseconds
                    setNextPaymentDate(date.toDateString())
                    const canceledAtPeriodEnd = subscriptionInfo.cancel_at_period_end; // Replace with your Firebase timestamp
                    setIsCanceled(canceledAtPeriodEnd)
                }
                else {
                    setCurrentSubscriptionPlan(trialPlan)
                }
            })


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])





    async function manageSubscriptionPlan() {
        if (!currentUser) {
            throw new Error("no current user")
        }
        setStripeLoading(true)
        firebaseInterface.getCustomerPortal()
    }


    return (
        <Flex flexDirection='column' maxHeight="97svh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
             <TopNavigationRow />
            <Flex width="100%" justifyContent={"flex-start"} mt="2"> <PreviousScreenButton previousScreen={location.state ? location.state.from : '/homeScreen'} /> </Flex>


            <Flex width={"100%"} flexDir={"column"} maxWidth={"900px"}>

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px' marginLeft="5px">
                        Abonnement
                    </Heading>
                </Flex>

            </Flex>
            <Flex flexDir="column" maxWidth={"900px"} rowGap="10px" justifyContent="center" alignItems={"flex-start"} width="100%">



                <Divider width="100%" mt='2' mb='2' />
                <Flex flexDir="column" width='80%' columnGap={'10px'} rowGap='15px' justifyContent={'flex-start'} alignItems='flex-start'>

                    <Flex columnGap={'5px'}>
                        <Text fontSize='lg'
                            casing={'uppercase'} color={theme.primaryColor}>
                            {currentSubscriptionPlan ? currentSubscriptionPlan.displayName : <Spinner size="xs" />}
                        </Text>
                    </Flex>

                    {currentSubscriptionPlan && currentSubscriptionPlan.id !== 'therapist_trial' &&
                        <Flex columnGap='10px' flexDir={'row'} rowGap='10px'>
                            <Button colorScheme={theme.colorScheme} variant='outline' size='sm' onClick={manageSubscriptionPlan} isLoading={stripeLoading}> Beheer / Upgrade Abonnement</Button>
                        </Flex>
                    }

                    {currentSubscriptionPlan && currentSubscriptionPlan.id === 'therapist_trial' &&
                        <Button colorScheme={theme.colorScheme} variant='outline' size='sm' as={Routerlink} to="/subscriptionPlanOverviewScreen" state={{ from: location.pathname }} > Upgrade</Button>

                    }




                    {currentSubscriptionPlan &&
                        <Flex flexDir='column' rowGap={'10px'}>
                            <Flex columnGap={'5px'}>
                                <Text
                                    fontWeight={'bold'}
                                    fontStyle={'italic'}
                                > Limieten</Text>
                            </Flex>

                            <Flex >
                                <Text  fontStyle={'italic'}  fontWeight='light' mr='1'>Gekoppelde patiënten:</Text>
                                <Text>{therapistProfile.patients.length}</Text>
                            </Flex>
                            <Flex >
                                <Text  fontStyle={'italic'}  fontWeight='light' mr='1'>Openstaande uitnodiging:</Text>
                                <Text>{numberActiveInvites}</Text>
                            </Flex>
                            <Flex>
                         
                            </Flex>
                            <Flex mt='-10px'>

                                <Text
                                    fontWeight={'bold'} color={theme.primaryColor} mr='2'mt='-3px'
                                >  Limiet patiënten + uitnodiging: </Text>
                                <ProgressBar  current={therapistProfile.patients.length + numberActiveInvites} total={currentSubscriptionPlan?.limitTotalPatients} />


                            </Flex>






                        </Flex>
                    }


                    {currentSubscriptionPlan && currentSubscriptionPlan.id !== 'therapist_trial' &&
                        <Flex columnGap={'5px'}>
                            {!isCanceled ?
                                <Text
                                    fontWeight={'bold'}
                                    fontStyle={'italic'}
                                > Volgende betaling:</Text>
                                :
                                <Text
                                    fontWeight={'bold'}
                                    fontStyle={'italic'}
                                > Verloopt:</Text>
                            }

                            <Text>
                                {nextPaymentDate}
                            </Text>
                        </Flex>
                    }


                </Flex>

            </Flex>
            <Flex mt='8' width={"100%"} flexDir={"column"} justifyContent='center' pb='40px' alignItems={'center'} maxWidth={"1500px"} onClick={therapistProfile.subscriptionId !== 'therapist_trial' ? manageSubscriptionPlan : () => { navigate('/subscriptionPlanOverviewScreen', {state: { from: location.pathname }}) }} >
                <Heading size='md'>Mogelijkheden</Heading>

                {!stripeLoading ?
                    <>
                        <SubscriptionPlanSelector currentPlan={currentSubscriptionPlan?.id ? currentSubscriptionPlan.id : "therapist_trial"} selectedPlan={currentSubscriptionPlan?.id ? currentSubscriptionPlan.id : "therapist_trial"} setSelectedPlan={() => { }} theme={theme} currency={'€'} includeTrial={false} />

                    </> :
                    <Spinner mt='40px' color={theme.thirdColor} size="xl" />
                }
            </Flex>

        </Flex >);
}



export default ManageSubscriptionScreen