import { useContext, useEffect, useState } from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Divider,
    Flex, Heading, Text
} from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import { Chat, insightReportOverview, Mood } from "../../types/types";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { ChatIcon } from "@chakra-ui/icons";
import MoodTrackingGraph from "../../components/moodtracker/MoodtrackingGraph";
import TopNavigationRow from "../../components/sharedComponents/topNavigationRow/TopNavigationRow";
import Moodtracker from "../../components/moodtracker/MoodTracker";
import InsightReportViewer from "../../components/patientScreens/InsightReportViewer";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";



function LinkedPatientInsightsScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const navigate = useNavigate();
    const firebaseInterface = useContext(FirebaseContext);
    const [loadingChat] = useState<boolean>(false);
    const [isSummaryLoaded, setIsSummaryLoaded] = useState<boolean>(false)
    const [chatSummary, setChatSummary] = useState<string>("")
    const [isChatLoaded, setIsChatLoaded] = useState<boolean>(false)
    const [chat, setChat] = useState<Chat>()
    const [todayMoods, setTodayMoods] = useState<Mood[]>([])
    const [insightReport, setInsightReport] = useState<insightReportOverview>();
    const [insightReportsLoaded, setInsightReportsLoaded] = useState<boolean>(false);
    const [chatInsightsAvailable, setChatInsightsAvailable] = useState<boolean>(false);
    const { patientId, chatId } = useParams<string>();
    const [today] = useState<Date>(firebaseInterface.dateFromFirebaseDateId(chatId!))
  
    const { therapistProfile } = useContext(UserProfileContext);

    const patientName = therapistProfile.patients ? therapistProfile.patients.find((p) => p.userId === patientId)?.name : undefined


    useEffect(() => {
        if (!chatId || !patientId) {
            throw new Error("User is not logged in")
        }
        firebaseInterface.fetchMoodsFromDate(patientId, firebaseInterface.dateFromFirebaseDateId(chatId)).then((moods) => {
            setTodayMoods(moods)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId])

    useEffect(() => {
        if (!chatId || !patientId) {
            throw new Error("User is not logged in")
        }

        if (firebaseInterface) {
            firebaseInterface.getChatSummary(patientId, chatId).then((summary) => {
                setChatSummary(summary)


                setIsSummaryLoaded(true)
            })

            firebaseInterface.getChatData(patientId, chatId).then((chat) => {
                setChatInsightsAvailable(completeInsightsAvailable(chat))

                setChat(chat)
                setIsChatLoaded(true)
            })
            .catch((error) => {
                console.log(error)
                setIsChatLoaded(true)
                setInsightReportsLoaded(true)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, chatId]);


    const checkIfUnratedInsightsAvailable = (chat: Chat): boolean => {
        if (!chat.insights) {
            return false
        }
        // check if there exists a 0 rating in goals
        if (chat.insights.goals && chat.insights.goals.length > 0) {
            const unratedGoals = chat.insights.goals.filter((g) => g.rating === 0)
            if (unratedGoals.length > 0) {
                return true
            }
        }
        // check if there exists a 0 rating in emotions_and_causes
        if (chat.insights.emotions_and_causes && chat.insights.emotions_and_causes.length > 0) {
            const unratedEmotions_and_causes = chat.insights.emotions_and_causes.filter((g) => g.rating === 0)
            if (unratedEmotions_and_causes.length > 0) {
                return true
            }
        }
        // check if there exists a 0 rating in important_thoughts
        if (chat.insights.important_thoughts && chat.insights.important_thoughts.length > 0) {
            const unratedImportant_thoughts = chat.insights.important_thoughts.filter((g) => g.rating === 0)
            if (unratedImportant_thoughts.length > 0) {
                return true
            }
        }
        // check if there exists a 0 rating in problems_and_complaints
        if (chat.insights.problems_and_complaints && chat.insights.problems_and_complaints.length > 0) {
            const unratedProblems_and_complaints = chat.insights.problems_and_complaints.filter((g) => g.rating === 0)
            if (unratedProblems_and_complaints.length > 0) {
                return true
            }
        }
        return false
    }

    // TODO toon chat alleen als dit er is
    const completeInsightsAvailable = (chat: Chat): boolean => {
        if (chat.insights && chat.insights.summary && chat.insights.goals && chat.insights.emotions_and_causes && chat.insights.important_thoughts && chat.insights.problems_and_complaints) {
            return true
        }
        return false
    }

    // load chats from firebase
    useEffect(() => {
        if (!patientId || !chat || !chatId) {
            return
        }
        firebaseInterface.getInsightReportByChatId(patientId, chatId).then((ir) => {
            if (ir) {
                setInsightReport({ insightReport: ir, newInsightsAvailable: checkIfUnratedInsightsAvailable(chat) })
            }
            setInsightReportsLoaded(true)
        })

        // eslint-disable-next-line
    }, [patientId, chat]);

   

    const startChat = async () => {
        if (!patientId) {
            throw new Error("no current user")
        }
        navigate(`/chatMessagesScreen/${patientId}/${chatId}`);


    }



    if (!isSummaryLoaded || !isChatLoaded || !insightReportsLoaded) {
        return (
            <Flex flexDirection='column' height={isMobile ? "88vh" : "99vh"} width="96%" alignItems="center" marginLeft="auto"
                marginRight="auto"   >
                <TopNavigationRow />

        <Flex width="90%" justifyContent={"flex-start"}    >
            <PreviousScreenButton previousScreen={`/LinkedPatientCalendarScreen/${patientId}`}  />



        </Flex>
                <Spinner color={theme.thirdColor} size="xl" />
            </Flex>
        );
    }


   





    return <Flex flexDirection='column' height={"100%"} width="96%" alignItems="center" marginLeft="auto"
        marginRight="auto"   >



        <TopNavigationRow />

        <Flex width="90%" justifyContent={"flex-start"}    >
            <PreviousScreenButton previousScreen={`/LinkedPatientCalendarScreen/${patientId}`}  />



        </Flex>


        <Heading size='lg' mt='-40px' color={'blackAlpha.800'}> {patientName}</Heading>
        <Heading size='lg' color={theme.primaryColor}> {today.toLocaleDateString()}</Heading>


        <Divider borderColor={theme.thirdColor} maxWidth='800px' mt='5' ></Divider>


        {todayMoods.length > 0 &&

            <Box >
                <Moodtracker moods={todayMoods} />
                <MoodTrackingGraph dailyMoods={todayMoods} height={200} width={320} />
            </Box>
        }

        <Divider borderColor={theme.thirdColor} maxWidth='800px' mt='5' mb='5' ></Divider>

        <Text fontSize={'xl'} color={theme.primaryColor} fontWeight={'bold'} mb='20px'>Dagelijkse conversatie</Text>

        <Button
            borderRadius='10px'
            width={'350px'}
            type="submit"
            variant="outline"
            colorScheme={theme.colorScheme}
            onClick={startChat}

            leftIcon={<ChatIcon />}
            isLoading={loadingChat}
        >
            Bekijk berichten conversatie
        </Button>

        <Accordion allowToggle>
            <AccordionItem borderColor={'white'} >

                <h2>
                    <AccordionButton _hover={undefined} bgColor={theme.fifthColor} borderRadius='50px' mt='20px'>
                        <Flex as="span" _hover={undefined} flex='1' textAlign='left' width={'300px'} justifyContent='center'>
                            <Text fontSize='md' fontWeight={'bold'} color={"blackAlpha.700"} >                             Toon samenvatting
                            </Text>
                        </Flex>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>

                <AccordionPanel>
                    <Flex width={'100%'} alignItems={'center'} flexDir={'column'} mt='20px'>
                        <Text fontSize={'md'} fontWeight={'bold'} mb='10px'>Samenvatting (AI gegenereerd)</Text>
                         <Text fontStyle={'italic'}>{chatSummary && chatSummary.length > 0 ? chatSummary : "Er zijn nog niet genoeg berichten beschikbaar om een samenvatting te genereren."}</Text>

                    </Flex>

                </AccordionPanel>
            </AccordionItem>
        </Accordion>




        <Flex width={'100%'} alignItems={'center'} flexDir={'column'} mt='20px' mb='100px'>



            <Flex>


                <Accordion allowToggle>
                    <AccordionItem borderColor={'white'} >

                        <h2>

                            <AccordionButton _hover={undefined} color={'black'} borderRadius='50px'
                                style={{
                                    background: theme.fifthColor,
                                    backgroundSize: "200% 100%",
                                    backgroundPosition: "100%",
                                }}>

                                <Flex as="span" _hover={undefined} flex='1' textAlign='left' width={'300px'} justifyContent='center' >

                                    <Text fontSize='md' color={"blackAlpha.700"} fontWeight={'bold'} >
                                        Toon conversatie inzichten
                                    </Text>

                                </Flex>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>

                        <AccordionPanel>
                            {chatInsightsAvailable ?
                                <>
                                    {insightReport ? (
                                        <InsightReportViewer insightReport={insightReport.insightReport} closeInsightReport={() => { }} newInsightsAvailable={insightReport.newInsightsAvailable} />
                                    ) :
                                        <Text> Gebruiker moet nog inzichten rapport opstellen</Text>
                                    }
                                </>
                                :
                                <Flex>
                                    <Text fontStyle={'italic'}>Gebruiker moet meer berichten sturen in deze conversatie voordat inzichten beschikbaar zijn </Text>

                                </Flex>
                            }
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>


            </Flex>


        </Flex>








    </Flex>
}


export default LinkedPatientInsightsScreen