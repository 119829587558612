import React, { useContext, useState } from 'react';
import { Divider, Flex, Text, Box, Button } from '@chakra-ui/react';
import { PatientInvite } from '../../types/types';
import { lavenderStyle, Theme } from '../../styles/styles';
import { UserProfileContext } from '../../contexts/userProfileContextProvider';
import { FirebaseContext } from '../../contexts/firebaseProvider';

interface InvitationCardProps {
  invitation: PatientInvite;
  handleDeleteFromInvitationList: any;
}

function InvitationCard(props: InvitationCardProps) {
  const [theme] = useState<Theme>(lavenderStyle);
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const { therapistProfile: userProfile, currentUser } = useContext(UserProfileContext);
  const firebaseInterface = useContext(FirebaseContext);
  const [isDeletingInvitation, setIsDeletingInvitation] = useState<boolean>(false)


  const handleDeleteClick = () => {
    setShowDeleteOptions(true);
  };

  const handleDelete = async () => {
    if(!currentUser) {
        return  
    }
    setIsDeletingInvitation(true)
    await firebaseInterface.deleteInvitation(currentUser.uid, props.invitation.tokenId)   
    props.handleDeleteFromInvitationList(props.invitation.tokenId)
    setIsDeletingInvitation(false)
    // delete invitation from firestore
  };

  const handleCancelDelete = () => {
    setShowDeleteOptions(false);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
      p={3}
      backgroundColor="white"
      width="220px" // Increased width for better content placement
    >
      <Text fontSize="xl" fontWeight="bold" mb={2}>
        {props.invitation.name}
      </Text>
      <Text fontSize="sm" color="gray.500" mb={2}>
        {props.invitation.description}
      </Text>

      <Text fontSize="sm" fontStyle="italic" color="gray.600" mb={2}>
        {props.invitation.timeStamp.toDate().toLocaleDateString()}
      </Text>

      <Divider my={2} />

      <Text fontSize="md" color={theme.primaryColor} mb={2}>
        Link Code:
      </Text>
      <Text fontSize="md" fontWeight={'bold'} color={theme.messageSenderTextColor} mb={2}>
        {props.invitation.tokenId}
      </Text>

      <Flex alignItems="center" mt={2}>
        <Text fontWeight="medium" fontSize="md" color={props.invitation.valid ? 'green.600' : 'red.600'}>
          {props.invitation.valid ? 'Ongebruikt' : 'Gebruikt'}
        </Text>
      </Flex>

      <Divider my={2} />
      <Flex alignItems="center" mt={2} width="100%" justifyContent="center">
        {!showDeleteOptions ? (
          <Button size="sm" onClick={handleDeleteClick}>
            Verwijder uitnodiging
          </Button>
        ) : (
          <>
            <Button size="sm" colorScheme="red" isLoading={isDeletingInvitation} onClick={handleDelete}>
              Verwijder 
            </Button>
            <Button size="sm" ml={2} onClick={handleCancelDelete}>
              Annuleer
            </Button>
          </>
        )}
      </Flex>
    </Box>
  );
}

export default InvitationCard;
