
import {
    Flex, Text, Button, Spinner, Switch, Heading, keyframes
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { lavenderStyle, Theme } from "../../styles/styles";
import { SubscriptionID } from "../../types/types";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { largePlan, mediumPlan, smallPlan, trialPlan } from "../../data/subscriptions/subscriptionPlans";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import SubscriptionPlanSelector from "../../components/subscriptionScreens/SubscriptionPlanSelector";
import TopNavigationRow from "../../components/sharedComponents/topNavigationRow/TopNavigationRow";

function SubscriptionPlanOverviewScreen() {

    const { therapistProfile, currentUser } = useContext(UserProfileContext);
    const [theme] = useState<Theme>(lavenderStyle)
    const [currentUserSubscriptionPlan] = useState<SubscriptionID>(therapistProfile.subscriptionId)
    const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<SubscriptionID>(therapistProfile.subscriptionId)
    const firebaseInterface = useContext(FirebaseContext);
    const [stripeLoading, setStripeLoading] = useState<boolean>(false)
    const location = useLocation();
    const [isFirstTime] = useState(location.state && location.state.isFirstTime);
    const navigate = useNavigate();

    const getSubscriptionPlanName = () => {
        switch (selectedSubscriptionPlan) {
            case 'therapist_small':
                return smallPlan.displayName
            case 'therapist_medium':
                return mediumPlan.displayName
            case 'therapist_large':
                return largePlan.displayName
            default:
                return trialPlan.displayName
        }
    }

    const shineAnimation = keyframes`
    0% {
      background-position: -200%;
    }
    100% {
      background-position: 200%;
    }
  `;

    async function buySubscriptionPlan() {
        let productId = ''
        switch (selectedSubscriptionPlan) {
            case 'therapist_small':
                productId = smallPlan.stripeProductIdEUR

                break;
            case 'therapist_medium':
                productId = mediumPlan.stripeProductIdEUR

                break;
            case 'therapist_large':
                productId = mediumPlan.stripeProductIdEUR

                break;
            default:
                return;
        }
        if (productId === '') {
            throw new Error("no product id")
        }

        if (!currentUser) {
            throw new Error("no current user")
        }

        firebaseInterface.createCheckoutSession(currentUser.uid, productId)
        setStripeLoading(true)
    }



    return (<Flex flexDirection='column' height={"99vh"} width="96%" alignItems="center" marginLeft="auto"
        marginRight="auto" >
             <TopNavigationRow />
        <Flex width="100%" justifyContent={"flex-start"} mt="2">
            {isFirstTime ? <PreviousScreenButton previousScreen="/homeScreen" /> : <PreviousScreenButton previousScreen={location.state.from} />}
        </Flex>

        <Flex width={"100%"} justifyContent="center" maxWidth={'1000px'}>
            <Heading size='lg' paddingTop='5px' marginLeft="5px">
            Kies je abonnement
            </Heading>
        </Flex>
        <Flex mt='5' width={"100%"} maxWidth={'800px'} justifyContent="center">
            {isFirstTime ? <Text fontStyle={'italic'} >               We bieden een gratis proefversie om je de werking van de AI therapeut te laten verkennen. </Text>
                : <Text fontStyle={'italic'} >               Je kan altijd je abonnement opzeggen of wijzigen.</Text>}
        </Flex>


        {!currentUserSubscriptionPlan || stripeLoading || !selectedSubscriptionPlan ?
            <Flex width="100%" height="100%" justifyContent={"center"} alignItems="center">
                <Spinner color={theme.thirdColor} size="xl" />
            </Flex>
            :


            <Flex width="100%"  alignItems={"center"} flexDirection='column'>
                <SubscriptionPlanSelector currentPlan={currentUserSubscriptionPlan} selectedPlan={selectedSubscriptionPlan} setSelectedPlan={setSelectedSubscriptionPlan} theme={theme} currency={'€' } includeTrial={isFirstTime} />
             
                <Flex justifyContent={'flex-end'} height="100%" alignItems={'center'}  mb='20px' mt='50px' >
                    {isFirstTime ?
                        <Button _hover={{ transform: "scale(1.05)" }}
                            transition="transform 0.2s"
                            animation={` ${shineAnimation} 4s infinite linear`}
                            _focus={{ boxShadow: "none" }}
                            style={{
                                background: `linear-gradient(to right, rgba(139, 139, 182, 1),rgba(97, 97, 153, 1), rgba(182, 182, 211, 1), rgba(139, 139, 182, 1))`,
                                backgroundSize: "200% 100%",
                                backgroundPosition: "100%",
                            }}
                            mb='10px'
                            size="lg" colorScheme={theme.colorScheme}
                            onClick={selectedSubscriptionPlan === 'therapist_trial' ? () => navigate('/homeScreen') : () => buySubscriptionPlan()} > {selectedSubscriptionPlan === 'therapist_trial' ? "Start " + t('SubscriptionPlan_trial_name') : "Verander naar " + getSubscriptionPlanName().toUpperCase()} </Button>

                        :
                        <Button size="lg" _hover={{ transform: "scale(1.05)" }}
                            transition="transform 0.2s"
                            animation={` ${shineAnimation} 4s infinite linear`}
                            _focus={{ boxShadow: "none" }}
                            mb='10px'
                            style={{
                                background: `linear-gradient(to right, rgba(139, 139, 182, 1),rgba(97, 97, 153, 1), rgba(182, 182, 211, 1), rgba(139, 139, 182, 1))`,
                                backgroundSize: "200% 100%",
                                backgroundPosition: "100%",
                            }} colorScheme={theme.colorScheme} isDisabled={selectedSubscriptionPlan === 'therapist_trial' || currentUserSubscriptionPlan === selectedSubscriptionPlan} onClick={() => buySubscriptionPlan()} > {"Verander naar "}{getSubscriptionPlanName().toUpperCase()}</Button>
                    }
                </Flex>

            </Flex>

        }




    </Flex>
    );
}

export default SubscriptionPlanOverviewScreen;
