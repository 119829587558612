import { useRef, useEffect, useState } from "react";
import {
    Flex
} from '@chakra-ui/react'
import Message from "./Message";
import { MessageType, TypeSpeed } from "../../types/types";

interface MessageBoxProps {
    messages: any;
    theme: any;
    innerRef: any;
}

function MessageBox(props: MessageBoxProps) {
    const elementRef = useRef<HTMLDivElement>(null);
    const theme = props.theme;
    const [typingMessage] = useState<MessageType>({
        message: "...",
        sender: "scroll",
        animation: true,
        timestampInSeconds: 0,
        isSaved: false
    });


    useEffect(() => {
        if (elementRef && elementRef.current) {
            elementRef.current.addEventListener('DOMNodeInserted', event => {
                // @ts-ignore
             
                const { relatedNode } = event;
                const className = relatedNode.classList[0];
                if(className === 'css-whrynk'){
                    return
                }
           
                    
                const { currentTarget: target } = event;
                // @ts-ignore


                // @ts-ignore
                target.scroll({ top: target.scrollHeight });
            });
        }
    }, [props.messages]);


    return (
        <Flex
            ref={elementRef}
            flexDirection="column"
            width="100%"
            height="100%"
            rowGap="12px"
            overflowY="auto"
            pb="40px"
        >
            {props.messages.map((message: MessageType, index: number) => (
                <Flex flexDirection="column" key={index}>
                    {message.sender === 'psychologist' && (
                        <Flex width="100%" justifyContent="flex-start" columnGap="1px" paddingLeft="2px" >
                            <Message key={index} theme={theme} message={message}/>
                        </Flex>
                    )}
                    {message.sender === 'user' && (
                        <Flex width="100%"
                            justifyContent="flex-end"
                        >
                            <Message key={index} theme={theme} message={message}  />
                        </Flex>
                    )}
                  
                </Flex>
            ))}

        </Flex>
    );

}

export default MessageBox;
