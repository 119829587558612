import { Button, Flex, HStack, Text } from "@chakra-ui/react";
import { GB, NL } from "country-flag-icons/react/3x2";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import FirebaseInterface from "../../services/firebase";
import { lavenderStyle, Theme } from "../../styles/styles";

function EmailNotVerifiedScreen() {
    const [theme] = useState<Theme>(lavenderStyle);
    const { currentUser } = useContext(UserProfileContext);
    const navigate = useNavigate();
    const [reloadScreen, setReloadScreen] = useState(false);

    const { i18n } = useTranslation();


    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };


    const handleReload = () => {
        setReloadScreen(true);
    };

    useEffect(() => {
        if (reloadScreen) {
            window.location.reload();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadScreen]);

    const sendNewVerificationLink = () => {
        const firebaseInterface = new FirebaseInterface();
        firebaseInterface.sendEmailVerification();
    };

    useEffect(() => {
        if (currentUser) {
            if (currentUser.metadata.creationTime === currentUser.metadata.lastSignInTime) {
                if (!localStorage.getItem('verificationEmailSent')) {
                    sendNewVerificationLink();
                }
                localStorage.setItem('verificationEmailSent', 'true');
            }
        }
        if (currentUser?.emailVerified) {
            localStorage.removeItem('verificationEmailSent');
            navigate("/languageSettingScreen");

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, currentUser?.emailVerified]);

    return (
        <Flex
            flexDir="column"
            width="100%"
            height="100vh"
            alignItems="center"
        >
           
            <Flex width='90%' flexDir={'column'} maxWidth='800px' mt='50px' justifyContent={'center'} alignItems='center'>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                Email Verificatie Verplicht
                </Text>

                <Text >
                Klik op de link die we naar je e-mail sturen om je account te verifiëren.
                </Text>
                <Text mt='10px' fontWeight={'bold'} color={theme.primaryColor}>Vergeet niet in je spam folder te kijken! @hotmail, @live, @outlook komen vaak bij ongewenste mails.</Text>
                <Text mt='5' fontWeight={'bold'}>
                    {currentUser?.email}
                </Text>

                <Text mb={4} mt={1}>

                </Text>
                <Flex columnGap={2} flexWrap='wrap' justifyContent={'center'} rowGap={4}>
                    <Button
                        colorScheme={theme.colorScheme}
                        variant={'outline'}
                        onClick={sendNewVerificationLink}
                        disabled={currentUser?.emailVerified}
                    >
                              Verificatie Email Opnieuw Verzenden
                    </Button>
                    <Button
                        colorScheme={theme.colorScheme}

                        onClick={handleReload}
                        disabled={!reloadScreen}
                    >
                              Check Verificatie Status
                    </Button>
                </Flex>

            </Flex>
        </Flex>
    );
}

export default EmailNotVerifiedScreen;
