import { useContext, useState } from "react";
import {
    Button,
    Divider,
    Flex, Heading, Image, Text
} from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Link as Routerlink, } from "react-router-dom";
import logo from '../../assets/general/PsyScribeLogo.svg';
import AddPatientButton from "../../components/sharedComponents/topNavigationRow/AddPatientButton";
import InvitesButton from "../../components/sharedComponents/topNavigationRow/InvitesButton";
import TopNavigationRow from "../../components/sharedComponents/topNavigationRow/TopNavigationRow";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import { FirebaseContext } from "../../contexts/firebaseProvider";



function AccountScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const location = useLocation();


    const { therapistProfile, currentUser, setTherapistProfile } = useContext(UserProfileContext);
    const firebaseInterface = useContext(FirebaseContext);

    const handleSignOut = () => {
        firebaseInterface.signOut();
    };


    return (
        <Flex flexDirection='column' maxHeight="97svh" height='100vh' width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >


            <TopNavigationRow />

            <Flex width="90%" justifyContent={"flex-start"}    >
            <PreviousScreenButton previousScreen={`/homeScreen`}  />

        </Flex>

            <Flex width={"100%"} flexDir={"column"} maxWidth={"900px"} mt='-40px'>

                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px' marginLeft="5px">
                       Gegevens
                    </Heading>
                </Flex>

            </Flex>


            <Flex flexDir="column" maxWidth={"900px"} rowGap="10px" justifyContent="center" alignItems={"flex-start"} width="100%">

                <Divider width="100%" mt='2' mb='2' />
                <Flex flexDir="row" width='80%' columnGap={'10px'} justifyContent={'flex-start'}>
                    <Text fontWeight={'bold'}>
                        Naam
                    </Text>
                    <Text>
                        {therapistProfile.name} 
                    </Text>
                    <Button colorScheme={theme.colorScheme} variant='outline' size='sm' as={Routerlink} to="/nameTherapistSettingScreen" state={{ from: location.pathname }}> Update</Button>

                </Flex>

                <Divider width="100%" mt='2' mb='2' />
                <Flex flexDir="row" width='80%' columnGap={'10px'} justifyContent={'flex-start'}>
                    <Text fontWeight={'bold'}>
                        Email:
                    </Text>
                    <Text>
                        {currentUser?.email}
                    </Text>
                </Flex>



            


            </Flex>
            <Divider width="100%" mt='2' mb='2' />
                <Button variant={'solid'} colorScheme={'red'} size='md' onClick={handleSignOut}> Log out</Button>
                <Divider width="100%" mt='2' mb='2' />

        </Flex>
    );
}



export default AccountScreen