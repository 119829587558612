import { useContext, useEffect, useState } from "react";
import {
    Button,
    Divider,
    Flex, Heading, IconButton, Switch, Text
} from '@chakra-ui/react'
import { Link as Routerlink, } from "react-router-dom";

import { lavenderStyle, Theme } from "../../styles/styles";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { PatientInvite } from "../../types/types";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import InvitationCard from "../../components/invitationsScreens/InvitationCard";
import { CloseIcon } from "@chakra-ui/icons";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import TopNavigationRow from "../../components/sharedComponents/topNavigationRow/TopNavigationRow";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function InvitationsOverviewScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const navigate = useNavigate()
    const [invitationsSent, setInvitationsSent] = useState<PatientInvite[]>([])
    const { therapistProfile: userProfile, currentUser } = useContext(UserProfileContext);
    const [showUnusedInvitations, setShowUnusedInvitations] = useState(false); // Step 1


    const firebaseInterface = useContext(FirebaseContext);


    const handleDeleteFromInvitationList = (tokenId: string) => {
        setInvitationsSent(invitationsSent.filter((invitation: PatientInvite) => invitation.tokenId !== tokenId))
    }
    const handleToggleUnusedInvitations = () => {
        setShowUnusedInvitations(!showUnusedInvitations); // Step 3
    };


    useEffect(() => {
        if (!currentUser) {

            return
        }
        // get all invitations sent by this therapist
        firebaseInterface.fetchInvitations(currentUser.uid).then((invitationsSent: PatientInvite[]) => {
            console.log(invitationsSent)
            setInvitationsSent(invitationsSent)
        }
        ).catch((error) => {
            console.log(error)
        }
        )

    }, [])

    return (
        <Flex flexDirection='column' maxHeight="97svh" height='100vh' width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
            
            <TopNavigationRow />
            <Flex width="90%" justifyContent={"flex-start"}  >
                <PreviousScreenButton previousScreen={"/homeScreen"} />
            </Flex>
            <Flex width={"100%"} mt='-30px' justifyContent="center">
                <Heading size='lg'  marginLeft="5px">
                  Verstuurde uitnodigingen
                </Heading>
            </Flex>

          

            <Divider mb='15px' mt='15px' maxWidth={'600px'}></Divider>


            <Flex flexDirection="row" alignItems="center" mt="2" mb='20px'>
                <Text >Toon gebruikte uitnodigingen:</Text>
                <Switch colorScheme={theme.colorScheme} isChecked={showUnusedInvitations} onChange={handleToggleUnusedInvitations} ml="2" />
            </Flex>

            <Flex flexDir={'column'} rowGap='15px' pb='20px'>
                {invitationsSent.map((invitation: PatientInvite) => {

                    if (!showUnusedInvitations && !invitation.valid) {
                        return null;
                    }
                    return <InvitationCard invitation={invitation} handleDeleteFromInvitationList={handleDeleteFromInvitationList} />

                })
                }
                {invitationsSent.length === 0 &&
                    <Flex flexDir={'column'} alignItems='center'>
                        <Text mb='10px' >U hebt geen openstaande uitnodigingen.</Text>
                        <Button as={Routerlink} to='/addPatientScreen' colorScheme={theme.colorScheme}> Nodig patient uit</Button>
                    </Flex>
                }
            </Flex>






        </Flex>
    );
}



export default InvitationsOverviewScreen