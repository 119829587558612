
import { useState, useRef, useEffect, useContext } from "react";
import {
    Text,
    Divider,
    useToast,
    Flex,
    Spinner,
    IconButton,
    Heading,
    Box,
} from '@chakra-ui/react'
import MessageBox from "../../components/chat/MessageBox";
import { lavenderStyle } from "../../styles/styles";
import { Theme } from "../../styles/styles";
import { t } from "i18next";
import { Language, MessageType, NotificationInferface, Personality, RateLimitCheck, TherapyStyle } from "../../types/types";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import AddPatientButton from "../../components/sharedComponents/topNavigationRow/AddPatientButton";
import PineconeInterface from "../../services/pinecone";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { ArrowBackIcon } from "@chakra-ui/icons";

/* eslint-disable no-useless-concat */
// TODO laad chats van  gebruiker zelf niet meer  in.

// Chats zijn niet op to date
function ChatMessagesScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const [messages, setMessages] = useState<MessageType[]>([]);
    const elementRef = useRef();
    const toast = useToast()
    const [messagesLoaded, setMessagesLoaded] = useState(false)
    const { patientId, chatId } = useParams<string>();
    const firebaseInterface = useContext(FirebaseContext);
    const navigate = useNavigate();



    // load messages from firebase
    useEffect(() => {
        if (!patientId || !chatId) {
            return;
        }
        getFirebaseStoredChatMessages().then((messages) => {
            if (messages && messages.length > 0) {
                setMessages(messages)
            }
            setMessagesLoaded(true)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messagesLoaded]);

    useEffect(() => {
        if (messagesLoaded) {

            setMessages((old) => [...old, { sender: "scroll", message: "", animation: false, timestampInSeconds: new Date().getTime() / 1000, isSaved: false }]);
        }
        // eslint-disable-next-line 
    }, [messagesLoaded])



    const getFirebaseStoredChatMessages = async () => {
        if (!patientId || !chatId) {
            return;
        }
        if (firebaseInterface) {
            const messages = await firebaseInterface.getMessages(patientId, chatId)
            let messageArray: MessageType[] = [];
            messages.forEach((savedMessage) => {
                let messageObject: MessageType = {
                    message: savedMessage.text,
                    sender: savedMessage.sender,
                    animation: false,
                    //@ts-ignore
                    timestampInSeconds: savedMessage.timestamp.seconds,
                    isSaved: false
                }
                messageArray.push(messageObject);
            })
            return messageArray;
        }
    }

    const navigateToOverViewScreen = async () => {

        navigate(`/linkedPatientInsightsScreen/${patientId}/${chatId}`);

    }


    return (<Flex flexDirection='column' height={"99vh"} width="96%" alignItems="center" marginLeft="auto" overflowY="auto"
        marginRight="auto"  >


        <Flex width="100%" justifyContent={"flex-start"} mt="2"   >




            <IconButton aria-label="Go back"
                variant="ghost"
                colorScheme={theme.colorScheme}
                icon={<ArrowBackIcon boxSize="9" />}

                size={"md"}
                borderRadius={theme.borderRadius} onClick={navigateToOverViewScreen}> </IconButton>
        </Flex>

        <Heading mt='-20px'>Chat </Heading>
        <Flex
            maxWidth="95%"
            alignItems={'center'}
            columnGap='10px'
        >
            <Text>Berichten: </Text>
            <Box
                paddingLeft="10px"
                paddingRight="10px"
                backgroundColor="#f5f6f8"
                borderRadius="10px"
                paddingBottom="5px"
                paddingTop="5px"
                marginLeft="5px"
                marginBottom={"5px"}
                marginTop={"5px"}
            >
                <Text color="#000">AI therapeut</Text>
            </Box>

            <Text
                paddingLeft="10px"
                paddingRight="10px"
                paddingBottom="5px"
                paddingTop="5px"
                backgroundColor={theme.messageBackgroundColor}
                borderRadius="10px"
                marginRight={"10px"}
                color={theme.messageTextColor}
            >
                Patiënt
            </Text>



        </Flex>



        <Divider color="red" maxWidth="900px" />
        {!messagesLoaded ?
            <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%" width="100%" maxWidth="900px">
                <Text color={theme.primaryColor} mb="5"> {t('ChatbotScreen_connection_text')}</Text>
                <Spinner color={theme.thirdColor} size="xl" />
            </Flex>
            : <Flex flexDirection="column" height="100%" width="100%" overflowY="auto" maxWidth="1000px" alignItems="center">
                <MessageBox theme={theme} innerRef={elementRef} messages={messages} />
            </Flex>}
    </Flex>
    )
}

export default ChatMessagesScreen
