import { useContext, useEffect, useState } from "react";
import {
    Button,
    Divider,
    Flex, Heading,  Text
} from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import { useNavigate } from "react-router-dom";
import { Link as Routerlink, } from "react-router-dom";
import TopNavigationRow from "../../components/sharedComponents/topNavigationRow/TopNavigationRow";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { Patient } from "../../types/types";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import PatientCard from "../../components/patientScreens/PatientCard";
import { faEnvelope, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InviteLimitExceededAlert from "../../components/alerts/InviteLimitExceededAlert";
import { largePlan, mediumPlan, smallPlan, trialPlan } from "../../data/subscriptions/subscriptionPlans";



function HomeScreen() {
    const [theme] = useState<Theme>(lavenderStyle)

    const { therapistProfile, currentUser, setTherapistProfile } = useContext(UserProfileContext);
    const firebaseInterface = useContext(FirebaseContext);
    const [currentPatients, setCurrentPatients] = useState<Patient[]>(therapistProfile.patients)
    const [showInviteLimitReachedAlert, setShowInviteLimitReachedAlert] = useState<boolean>(false)
    const navigate = useNavigate()
    const [numberOfPatientsAllowed, setNumberOfPatientsAllowed ]= useState<number>(trialPlan.limitTotalPatients)
    
    useEffect(() => {
        
       
        const subscriptionid = therapistProfile.subscriptionId
        
        if (subscriptionid === 'therapist_small') {
            setNumberOfPatientsAllowed( smallPlan.limitTotalPatients)
        }
        else if (subscriptionid === 'therapist_medium') {
            setNumberOfPatientsAllowed( mediumPlan.limitTotalPatients)

        }
        else if (subscriptionid === 'therapist_large') {
            setNumberOfPatientsAllowed( largePlan.limitTotalPatients)

        }

        fetchPatients()
    }, [])

    const fetchPatients = async () => {
        if (!currentUser) {
            return
        }
        const patients = await firebaseInterface.fetchPatients(currentUser.uid)
        setCurrentPatients(patients)
        setTherapistProfile({ ...therapistProfile, patients: patients })
    }
    const isAllowedToAddPatient = async () => {
        if (!currentUser) {
            return false

        }

        const allInvites = await firebaseInterface.fetchInvitations(currentUser.uid)
        let nValidInvites = 0
        allInvites.forEach((invite) => {
            if (invite.valid) {
                nValidInvites += 1
            }
        })
        let patientsAndOpenInvites = currentPatients.length + nValidInvites
        if(patientsAndOpenInvites >= numberOfPatientsAllowed){
            return false
        }


        //if (nTokens >= 5) {
        //    return false
        //}
        return true
    }

    const invitePatient = async () => {
        isAllowedToAddPatient().then((isAllowed) => {   
            if (!isAllowed) {
                setShowInviteLimitReachedAlert(true)
                return
            }
            navigate('/addPatientScreen')
        }
        )
    }



    return (
        <Flex flexDirection='column' maxHeight="97svh" height='100vh' width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >


            <TopNavigationRow />

            {showInviteLimitReachedAlert && <InviteLimitExceededAlert onClose={() => setShowInviteLimitReachedAlert(false)} />}
            <Heading>Gekoppelde patiënten</Heading>
            <Divider mb='15px' mt='15px' maxWidth={'600px'}></Divider>


            <Flex flexDir="row" columnGap={'10px'} maxWidth={"900px"} justifyContent="center" alignItems={"center"} >
                <Button leftIcon={<FontAwesomeIcon icon={faUserPlus} />} onClick={invitePatient} colorScheme={theme.colorScheme}> Voeg patiënt toe</Button>
                <Button leftIcon={<FontAwesomeIcon icon={faEnvelope} />} as={Routerlink} to='/invitationsOverviewScreen' colorScheme={theme.colorScheme}> Bekijk uitnodigingen</Button>

            </Flex>
            <Divider mb='15px' mt='15px' maxWidth={'600px'}></Divider>
            <Flex flexDir={'column'} rowGap='15px'>
                {currentPatients.map((patient: Patient) => {
                    return <PatientCard patient={patient} />
                })
                }
                {currentPatients.length === 0 &&
                    <Flex flexDir={'column'} alignItems='center'>
                        <Text mb='10px' >U hebt nog geen patienten gekoppeld</Text>
                    </Flex>
                }
            </Flex>
        </Flex>
    );
}



export default HomeScreen