// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { addDoc, collection, deleteDoc, doc, FieldValue, Firestore, getCountFromServer, getDoc, getDocs, getFirestore,  limit, onSnapshot, orderBy, query, serverTimestamp, setDoc, Timestamp, where } from 'firebase/firestore';
import { getAuth, signOut, Auth, sendEmailVerification } from "firebase/auth";
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';

import { Chat, MessageType, TherapistSettings, UserProfile, firebaseStoredInsightReport, UsageStatistics,  NotificationInferface, NotificationFetchedFromDatabase, TherapistProfile, PatientInvite, Patient, Mood } from "../types/types";
import initializeStripe from "./stripe";


type FirebaseMessage = {
    id: string;
    text: string;
    sender: "user" | "psychologist";
    timestamp: FieldValue;
}

class FirebaseInterface {
    app: FirebaseApp;

    db: Firestore;

    auth: Auth;

    functions: Functions


    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    //firebaseConfig = {
    //    apiKey: "AIzaSyAXkD7q-McJhxpJqcllaensyb-7jMWXKuY",
    //    authDomain: "psychobot-90921.firebaseapp.com",
    //    projectId: "psychobot-90921",
    //    storageBucket: "psychobot-90921.appspot.com",
    //    messagingSenderId: "953365192676",
    //    appId: "1:953365192676:web:a5ce2993a28dd2a37531f1",
    //    measurementId: "G-G1256GNREM"
    //};

    firebaseConfig = {
        apiKey: "AIzaSyAXkD7q-McJhxpJqcllaensyb-7jMWXKuY",
        authDomain: "auth.psyscribe.com",
        projectId: "psychobot-90921",
        storageBucket: "psychobot-90921.appspot.com",
        messagingSenderId: "953365192676",
        appId: "1:953365192676:web:a5ce2993a28dd2a37531f1",
        measurementId: "G-G1256GNREM"
    };

    constructor() {
        this.app = initializeApp(this.firebaseConfig);
        this.db = getFirestore(this.app);
        this.auth = getAuth(this.app);
        this.functions = getFunctions(this.app, 'europe-west3');
    }

    logOut() {
        signOut(this.auth);
    }




    async addClientSignUpToken(therapistId: string, clientLoginToken: string) {
        const col = collection(this.db, "Therapists", therapistId, "clientSignUpTokens");
        // Reference to a specific document
        const documentRef = doc(col, clientLoginToken);

        // Set document data
        await setDoc(documentRef, {
            clientId: clientLoginToken,
            timestamp: serverTimestamp(),
        });
    }

    async checkIfClientSignUpTokenExists(therapistId: string, clientLoginToken: string) {
        const col = collection(this.db, "Therapists", therapistId, "clientSignUpTokens");
        // Reference to a specific document
        const documentRef = doc(col, clientLoginToken);
        const docSnap = await getDoc(documentRef);
        if (docSnap.exists()) {
            return true;

        }
        return false;
    }

    async countSignUpTokens(therapistId: string) {

        const col = collection(this.db, "Therapists", therapistId, "clientSignUpTokens");

        const snapshot = await getCountFromServer(col);
        return snapshot.data().count;
    }

    async fetchTherapistProfile(uid: string): Promise<any> {
        const docRef = doc(this.db, "Therapists", uid);
        const docSnap = await getDoc(docRef);
        const subscription = await this.getSubscriptionInfo(uid);


        if (docSnap.exists()) {
            const data = docSnap.data();
            const therapistProfile: TherapistProfile = {
                name: data.name,
                notifications: [],
                subscriptionId: subscription ? subscription.role : "therapist_trial",
                patients: await this.fetchPatients(uid),
            }
            return therapistProfile;
        }
        return null;
    }



    async checkIfTherapistExistsInFirestore(userId: string): Promise<boolean> {
        const docRef = doc(this.db, "Therapists", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return true;
        }
        return false;
    }

    getServerTimestamp() {
        return serverTimestamp();
    }

    async invitePatient(therapistId: string, invitation: PatientInvite) {
        // add in invitations collection
        const col = collection(this.db, "Therapists", therapistId, "invitations");
        // Reference to a specific document
        const documentRef = doc(col, invitation.tokenId);

        // Set document data
        await setDoc(documentRef, invitation);

    }

    async fetchInvitations(therapistId: string) {

        const invitationsRef = collection(this.db, "Therapists", therapistId, "invitations");

        const invitations: PatientInvite[] = [];

        // get all invitations
        const querySnapshot = await getDocs(
            query(invitationsRef, orderBy("timeStamp", "desc"))
        );


        querySnapshot.forEach((doc) => {

            let invitation: PatientInvite = {

                name: doc.data().name,
                tokenId: doc.data().tokenId,
                timeStamp: doc.data().timeStamp,

                description: doc.data().description,
                valid: doc.data().valid,

            }
            invitations.push(invitation);

        });

        return invitations;
    }

    async deleteInvitation(therapistId: string, tokenId: string) {
        const invitationRef = doc(this.db, "Therapists", therapistId, "invitations", tokenId);
        await deleteDoc(invitationRef);
    }

    async fetchPatients(therapistId: string) {
        const patientsRef = collection(this.db, "Therapists", therapistId, "patients");

        const patients: Patient[] = [];
        // get all patients
        const querySnapshot = await getDocs(patientsRef)

        querySnapshot.forEach((doc) => {
            let patient: Patient = {
                name: doc.data().name,
                description: doc.data().description,
                userId: doc.data().userId,

            }
            patients.push(patient);
        }
        );
        return patients;
    }


    async updatePatient(therapist_id: string, patient: Patient) {
        const patientRef = doc(this.db, "Therapists", therapist_id, "patients", patient.userId);
        await setDoc(patientRef, patient);
    }

    async unlinkClientFromTherapist(therapistId: string, patientId: string) {
        const url = 'https://unlink-client-w-therapist-4gncbogdka-uc.a.run.app'
        const data = {
            patient_id: patientId,
            therapist_id: therapistId
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return response.json()

    }





    async fetchProfile(userId: string): Promise<any> {
        const docRef = doc(this.db, "Users", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            const therapistSettings: TherapistSettings = {
                therapyStyle: data.therapyStyle,
                personality: data.personality,
                psychologistName: data.psychologistName,
                typeSpeed: data.typeSpeed,
                avatar: data.avatar,
                rememberedMessages: await this.getRememberedMessages(userId)
            }
            const profile: UserProfile = {
                username: data.userName,
                language: data.language,
                therapistSettings: therapistSettings,
                dailyStats: await this.getDailyStats(userId),
                totalStats: await this.getTotalStats(userId),
                subscriptionId: "therapist_admin",
                notifications: await this.fetchNotifications(userId),
            }
            return profile;
        }
        return null;
    }



    async getSubscriptionInfo(uid: string) {
        try {
            const collectionRef = collection(this.db, "customers", uid, 'subscriptions');
            const querySnapshot = await getDocs(
                query(
                    collectionRef,
                    where('status', 'in', ['trialing', 'active'])
                )
            );
            const subscriptions = querySnapshot.docs.map((doc) => (
                doc.data()

            ));


            let most_expensive_subscription: any = null;
            let most_expensive_subscription_role = ""
            subscriptions.forEach((subscription) => {
                if (subscription.status === "active") {
                    let role = subscription.role
                    // check if role starts with therapist  
                    if (role.startsWith("therapist")) {
                        if (most_expensive_subscription == null) {
                            most_expensive_subscription = subscription
                            most_expensive_subscription_role = role
                        }
                        else if (most_expensive_subscription_role === "therapist_trial") {
                            if (role === "therapist_small" || role === "therapist_medium" || role === "therapist_large") {
                                most_expensive_subscription = subscription
                                most_expensive_subscription_role = role
                            }

                        }
                        else if (most_expensive_subscription_role === "therapist_small") {
                            if (role === "therapist_medium" || role === "therapist_large") {
                                most_expensive_subscription = subscription
                                most_expensive_subscription_role = role
                            }
                        }

                        else if (most_expensive_subscription_role === "therapist_medium") {

                            if (role === "therapist_large") {
                                most_expensive_subscription = subscription
                                most_expensive_subscription_role = role
                            }
                        }
                    }
                }
            })


            return most_expensive_subscription;
        } catch (error) {
        }
    }


    async checkIfUserExistsInFirestore(userId: string): Promise<boolean> {
        const docRef = doc(this.db, "Users", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return true;
        }
        return false;
    }

    sendEmailVerification() {
        if (this.auth.currentUser) {
            sendEmailVerification(this.auth.currentUser)
                .then(() => {
                    // Email verification sent!
                    // ...
                });
        }
    }




    updateUserDescriptionDebug(userId: string, user_description: string) {
        const newUserDescription = {
            user_description: user_description,
            timestamp: serverTimestamp(),
        };

        const col = collection(this.db, "Users", userId, "user_descriptions");
        addDoc(col, newUserDescription)
    }

    updateChatSummary(uid: string, newSummary: string, chatId: string) {
        const newChatSummary = {
            chatSummary: newSummary,
            timestamp: serverTimestamp(),
        };
        const chat_summary_doc = doc(this.db, "Users", uid, "chats", chatId);
        setDoc(chat_summary_doc, newChatSummary, { merge: true })
    }

    async getChatSummary(uid: string, chatId: string) {
        // check if chat summary exists
        const chat_summary_doc = doc(this.db, "Users", uid, "chats", chatId);
        const chat_summary_doc_snap = await getDoc(chat_summary_doc);
        if (chat_summary_doc_snap.exists()) {
            return chat_summary_doc_snap.data().chatSummary;
        }
        return "";
    }




  

    async getInsightReports(uid: string) {
        const insightReportsRef = collection(this.db, "Users", uid, "insight_reports");
        const querySnapshot = await getDocs(
            query(
                insightReportsRef,
                orderBy('timestamp', 'desc'),
            )
        );
        const firebaseStoredInsightReports: firebaseStoredInsightReport[] = querySnapshot.docs.map((doc) => (
            {
                report: doc.data().report,
                insightReportId: doc.id,
            }
        )).reverse();

        return firebaseStoredInsightReports;
    }

    async getInsightReportByChatId(uid: string, chatId: string) {
        const insightReportsRef = doc(this.db, "Users", uid, "insight_reports", chatId);
        const docSnap = await getDoc(insightReportsRef);
        if (docSnap.exists()) {
            return (

                {
                    report: docSnap.data().report,
                    insightReportId: docSnap.id,
                })

        }
        return null;
    }





   

    async getRememberedMessages(uid: string) {
        const rememberedMessagesRef = collection(this.db, "Users", uid, "remembered_messages");
        const querySnapshot = await getDocs(
            query(
                rememberedMessagesRef,
                orderBy('timestamp', 'desc'),
                limit(100)
            )
        );
        const rememberedMessages: MessageType[] = querySnapshot.docs.map((doc) => (
            doc.data().message
        )).reverse();
        return rememberedMessages;

    }

   



    async getDailyStats(uid: string): Promise<UsageStatistics> {
        const dailyStatsRef = doc(this.db, "Users", uid, "daily_stats", this.getCurrentDate());
        const docSnap = await getDoc(dailyStatsRef);
        if (docSnap.exists()) {
            const dailyStats = docSnap.data() as UsageStatistics;
            return dailyStats;
        } else {
            return {
                totalMessagesSent: 0,
                totalMessagesRemembered: 0,
                totalInsightReportsGenerated: 0,
                totalChatsCreated: 0,
            };
        }
    }

    async getTotalStats(uid: string): Promise<UsageStatistics> {
        const totalStatsRef = doc(this.db, "Users", uid, "total_stats", "total_stats");
        const docSnap = await getDoc(totalStatsRef);
        if (docSnap.exists()) {
            const totalStats = docSnap.data() as UsageStatistics;
            return totalStats;
        } else {
            return {
                totalMessagesSent: 0,
                totalMessagesRemembered: 0,
                totalInsightReportsGenerated: 0,
                totalChatsCreated: 0,
            };
        }
    }



   


    async addNotication(uid: string, notification: NotificationInferface) {
        const notificationsRef = collection(this.db, "Users", uid, "notifications");
        // add  with timestamp
        await addDoc(notificationsRef, {
            notification: notification,
            serverTimestamp: serverTimestamp(),
        });
    }

    async setNotificationAsRead(uid: string, notificationId: string) {
        const notificationRef = doc(this.db, "Users", uid, "notifications", notificationId);
        // set notification.isNew to false
        await setDoc(notificationRef, {
            notification: {
                isNew: false,
            },
        }, { merge: true });

    }


    async fetchNotifications(uid: string) {
        const notificationsRef = collection(this.db, "Users", uid, "notifications");

        const notifications: NotificationFetchedFromDatabase[] = [];

        // Get the latest two chats ordered by serverTimestamp
        const querySnapshot = await getDocs(
            query(notificationsRef, orderBy("serverTimestamp", "desc"))
        );

        querySnapshot.forEach((doc) => {
            let notification: NotificationFetchedFromDatabase = {
                notification: doc.data().notification as NotificationInferface,
                firebaseDocumentId: doc.id,
            }
            notifications.push(notification);
        });



        return notifications;
        // er moet een listener op de notifications collection komen in een context provider, maar dit moet ook al ggeteched wordenn bij het eerste fetchen van profiel

    }

    async deleteNotification(uid: string, notificationId: string) {
        const notificationRef = doc(this.db, "Users", uid, "notifications", notificationId);
        await deleteDoc(notificationRef);
    }

    async fetchMoodsFromDate(userId: string, date: Date) {
        const moodRef = collection(this.db, "Users", userId, "moods", this.dateToFirebaseDateId(date), "moods");
        const querySnapshot = await getDocs(
            query(
                moodRef,
                orderBy('timestamp', 'desc'),
            )
        );
        const moods: Mood[] = querySnapshot.docs.map((doc) => (
            doc.data().mood
        )).reverse();
        return moods;
    }


    async fetchAllMoods(userId: string) {

        const moodRef = collection(this.db, "Users", userId, "moods");
        // get all docs in this collection
        try {
            const querySnapshot = await getDocs(moodRef);
            const dayIdToMoodsMap: Map<string, Mood[]> = new Map();

     

            const docIds: string[] = []
            querySnapshot.forEach((doc) => {
                // Assuming your mood documents have some fields, you can access them like this:
                // log doc.id
                // fetch all moods from this doc
                docIds.push(doc.id)

            });
            for (let i = 0; i < docIds.length; i++) {
                let moods: Mood[] = []
                let moodRef2 = collection(this.db, "Users", userId, "moods", docIds[i], "moods");
                let querySnapshot2 = await getDocs(moodRef2);

                querySnapshot2.forEach((doc) => {
                    // Assuming your mood documents have some fields, you can access them like this:
                    // log doc.id
                    // fetch all moods from this doc
                    moods.push(doc.data().mood)

                });


                dayIdToMoodsMap.set(docIds[i], moods)
            }


            return dayIdToMoodsMap;
        } catch (error) {
            console.error("Error fetching moods: ", error);
            throw error;
        }

    }


    getCurrentDate() {
        const timestamp = Timestamp.now();
        const date = timestamp.toDate();
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        return formattedDate;
    }

    dateToFirebaseDateId(date: Date) {
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        return formattedDate;
    }

    dateFromFirebaseDateId(dateId: string) {
        const dateParts = dateId.split("-");
        const date = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
        return date;
    }



   

    async getChatData(uid: string, chatId: string) {
        try {
            const chatRef = doc(this.db, "Users", uid, "chats", chatId);
            const docSnap = await getDoc(chatRef);
            const data = docSnap.data();

            
            // get date of last message sent
            const messagesRef = collection(chatRef, "messages");
            const querySnapshot = await getDocs(
                query(
                    messagesRef,
                    orderBy('timestamp', 'desc'),
                    limit(1)
                )
            );
            const lastMessage = querySnapshot.docs.map((doc) => (
                doc.data()
            ))[0];
            const chatData: Chat = {
                name: "chatName",
                lastMessageDateInMilliseconds: lastMessage ? lastMessage.timestamp.seconds * 1000 : new Date().getTime(),
                insights: data?.insights?.insights,
                databaseId: chatId,

            }
            return chatData;
        } catch (error) {
            throw new Error('error')
        }
    }



    async getChatMetaData(uid: string, chadId: string) {
        try {
            const chatRef = doc(this.db, "Users", uid, "chats", chadId);
            const docSnap = await getDoc(chatRef);
            const data = docSnap.data();

            const chatName = data?.metadata.name;
            // get date of last message sent
            const messagesRef = collection(chatRef, "messages");
            const querySnapshot = await getDocs(
                query(
                    messagesRef,
                    orderBy('timestamp', 'desc'),
                    limit(1)
                )
            );
            const lastMessage = querySnapshot.docs.map((doc) => (
                doc.data()
            ))[0];
            const metadata = {
                name: chatName,
                lastMessageDateInMilliseconds: lastMessage ? lastMessage.timestamp.seconds * 1000 : new Date().getTime(),
                insights: data?.insights?.insights,
            }
            return metadata;
        }
        catch (error: any) {
            throw new Error(error)
        }
    }





    async getMessages(uid: string, chatId: string) {
        const querySnapshot = await getDocs(
            query(
                collection(this.db, "Users", uid, 'chats', chatId, 'messages'),
                orderBy('timestamp', 'desc'),
                limit(1000)
            )
        );
        const messages: FirebaseMessage[] = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            text: doc.data().text,
            sender: doc.data().sender,
            timestamp: doc.data().timestamp,
        })).reverse();
        return messages;
    }



    async getMessageCount(uid: string, chatId: string) {
        const coll = collection(this.db, "Users", uid, 'chats', chatId, 'messages');
        const snapshot = await getCountFromServer(coll);
        return snapshot.data().count;
    }

    async updateTherapistName(uid: string, therapistName: string) {

        const docRef = doc(this.db, "Therapists", uid);

        await setDoc(docRef, { name: therapistName }, { merge: true });

    }


    async createCheckoutSession(uid: string, priceId: string) {
        const checkoutSessionRef = await addDoc(collection(this.db, "customers", uid, 'checkout_sessions'), {
            price: priceId,
            success_url: 'https://dashboard.psyscribe.com/subscriptionBoughtScreen',
            cancel_url: 'https://dashboard.psyscribe.com/homeScreen',

        });
        // Wait for the CheckoutSession to get attached by the extension
        onSnapshot(doc(this.db, "customers", uid, 'checkout_sessions', checkoutSessionRef.id), async (snap) => {
            const data = snap.data();
            if (data) {
                const { error, sessionId } = data;
                if (sessionId) {
                    // We have a session, let's redirect to Checkout
                    // Init Stripe
                    const stripe = await initializeStripe();
                    stripe?.redirectToCheckout({ sessionId });
                } else if (error) {
                    // No session, let's display the error message
                    alert(`An error occured: ${error.message}`);
                }
            }
        });
    }
    async getCustomerPortal() {
        const functionRef = httpsCallable(this.functions, 'ext-firestore-stripe-payments-createPortalLink');
        //const functionRef = this.functions
        //    .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
        //    
        const { data } = await functionRef({
            returnUrl: 'https://dashboard.psyscribe.com/homeScreen',
            locale: "auto", // Optional, defaults to "auto" // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
        });
        //@ts-ignore
        window.location.assign(data.url);
    }


    async signOut() {
        this.auth.signOut();
    }
}

export default FirebaseInterface


