import { useContext, useEffect, useRef, useState } from "react";
import {
    Flex,
    Heading,
    IconButton,
    Input,
    Button,
    Divider,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from "@chakra-ui/react";
import {
    lavenderStyle,
    Theme
} from "../../styles/styles";
import { useNavigate, useParams } from "react-router-dom";
import { Chat, Patient } from "../../types/types";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { useToast } from "@chakra-ui/react";

function LinkedPatientSettingsScreen() {
    const [theme] = useState<Theme>(lavenderStyle);
    const navigate = useNavigate();
    const { therapistProfile, currentUser, setTherapistProfile } = useContext(UserProfileContext);
    const firebaseInterface = useContext(FirebaseContext);
    const [currentPatients, setCurrentPatients] = useState<Patient[]>(therapistProfile.patients);
    const { patientId } = useParams<string>();
    const [patient, setPatient] = useState<Patient | null>(null);
    const [chatsLoaded, setChatsLoaded] = useState<boolean>(false);
    const [chats, setChats] = useState<Chat[]>([]);
    const toast = useToast(); // Initialize the toast hook
    const [isDeletingPatient, setIsDeletingPatient] = useState<boolean>(false);


    const [newName, setNewName] = useState<string>("");
    const [newDescription, setNewDescription] = useState<string>("");
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const onCloseDeleteDialog = () => setIsDeleteDialogOpen(false);
    const cancelDeleteRef = useRef(null);


    const handleGoBack = () => {
        navigate(`/LinkedPatientCalendarScreen/${patientId}`);
    };

    const handleUpdate = async () => {
        if (!patient || !currentUser) return;

        // Update patient's name and description
        const updatedPatient = {
            ...patient,
            name: newName,
            description: newDescription,
        };

        // Update in local state
        setPatient(updatedPatient);

        // Update in database
        const updatedPatients = currentPatients.map((p) =>
            p.userId === patient.userId ? updatedPatient : p
        );

        setCurrentPatients(updatedPatients);
        setTherapistProfile({ ...therapistProfile, patients: updatedPatients });


        await firebaseInterface.updatePatient(currentUser.uid,updatedPatient);

         // Show a toast notification when the patient is updated
         toast({
            title: "Gegevens bijgewerkt",
            description: "De gegevens van de patient zijn bijgewerkt.",
            status: "success",
            duration: 5000,
            position: "top",
            
            isClosable: true,
        });

        //await firebaseInterface.updatePatient(updatedPatient);
    };


 
    const handleDelete = async () => {
        if (!patient || !currentUser) return;

        setIsDeletingPatient(true);
        // Delete the patient from the local state
        const updatedPatients = currentPatients.filter(p => p.userId !== patient.userId);
        setCurrentPatients(updatedPatients);
        setTherapistProfile({ ...therapistProfile, patients: updatedPatients });

      

        // Delete the patient in Firebase
        //await firebaseInterface.deletePatient(patient.userId);
    
     
        await firebaseInterface.unlinkClientFromTherapist(currentUser.uid, patient.userId)
        setIsDeletingPatient(false);
        // Navigate back to the linkedPatientScreen or any desired destination
        navigate(`/homeScreen`);
    };


    useEffect(() => {
        if (!patientId) {
            return
        }
        // check if patient is already in currentPatients
        const patient = currentPatients.find((patient) => patient.userId === patientId)
        if (patient) {
            setPatient(patient)
            setNewName(patient.name)
            setNewDescription(patient.description)
            return
        }
    }, [patientId])

    return (
        <Flex flexDirection="column" maxHeight="97vh" height="100vh" width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px">
            <Flex width="100%" justifyContent="flex-start" mt="2">
                <IconButton
                    aria-label="Go back"
                    variant="ghost"
                    colorScheme={theme.colorScheme}
                    icon={<ArrowBackIcon boxSize="9" />}
                    size={"lg"}
                    borderRadius={theme.borderRadius}
                    onClick={handleGoBack}
                />
            </Flex>
            <Flex width={"100%"} justifyContent="center">
                <Heading size="lg" paddingTop="5px" marginLeft="5px">
                    {patient?.name}
                </Heading>
            </Flex>


            <Flex width="80%" flexDirection="column" mt="4">
                <label>Naam</label>
                <Input
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    placeholder="New Name"
                />
            </Flex>
            <Flex width="80%" flexDirection="column" mt="2">
                <label>Beschrijving</label>
                <Input
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                    placeholder="New Description"
                />
            </Flex>
            <Button
                colorScheme={theme.colorScheme}
                onClick={handleUpdate}
                width="80%"
                mt="4"

                maxWidth={'600px'}
            >
                Update gegevens
            </Button>


            <Button
                colorScheme="red"
                onClick={() => setIsDeleteDialogOpen(true)}
                width="80%"
                mt="4"
                maxWidth={'600px'}
            >
                                            Verwijder Patient

            </Button>

            <AlertDialog
                isOpen={isDeleteDialogOpen}
    
                leastDestructiveRef={cancelDeleteRef}
                onClose={onCloseDeleteDialog}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Verwijder Patient
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Ben je zeker dat je deze patient wilt verwijderen? Dit kan niet ongedaan gemaakt worden.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                 
                            <Button ref={cancelDeleteRef} onClick={onCloseDeleteDialog}>
                                Annuleer
                            </Button>
                            <Button isLoading={isDeletingPatient} colorScheme="red" onClick={handleDelete} ml={3}>
                                Verwijder
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>



        </Flex>
    );
}

export default LinkedPatientSettingsScreen;
