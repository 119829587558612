
import {
    Flex,
    Heading,
    Image
} from "@chakra-ui/react";
import LoginButton from "../../components/loginscreen/LoginButton";
import logo from '../../assets/general/PsyScribeLogo.svg';

import { lavenderStyle, Theme } from "../../styles/styles";
import { useState } from "react";


function LoginScreen() {

    const [theme] = useState<Theme>(lavenderStyle)


    return (<Flex
            flexDirection="column"
            alignItems="center"
            width="96%"
            marginX="auto"
            height="100vh"
        >


              <Image mt='20px' width='500px' height='100px' src={logo} alt="My Logo" />

         
            <Flex mt='50px' justifyContent='center' flexDir='column' >


                <Heading color={theme.primaryColor} mb='20px'>Therapist Dashboard Login</Heading>
            
            <LoginButton />
            </Flex>

        </Flex>
    );
}

export default LoginScreen;
