
import { Divider, Flex, Text, Box } from '@chakra-ui/react';
import { Patient } from '../../types/types';
import { useNavigate } from 'react-router-dom';

interface InvitationCardProps {
  patient: Patient;
}

function PatientCard(props: InvitationCardProps) {
  const navigate = useNavigate();


  const navigateToPatientScreen = async () => {

    // navigate to chat
    navigate(`/LinkedPatientCalendarScreen/${props.patient.userId}`);

  }

  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
      p={2}
      backgroundColor="white"
      width="220px"
    

      onClick={navigateToPatientScreen}
      _hover={{ cursor: 'pointer', boxShadow: 'lg', transform: 'scale(1.02)', transition: 'all 0.2s ease-in-out', }}
    >
      <Flex width={'100%'} justifyContent='center'>
      <Text fontSize="xl" fontWeight="bold" >
        {props.patient.name}
      </Text>
      </Flex>

        <Divider mt='5px' mb='5px'></Divider>
      <Text fontSize="sm" color="gray.500">

        {props.patient.description? props.patient.description : "Geen beschrijving opgegeven"}
      </Text>



     
    </Box>
  );
}

export default PatientCard;
