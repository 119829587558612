import { Routes, Route } from "react-router-dom";
import LoginScreen from "../pages/loginScreens/LoginScreen";
import ProtectedAuthAndProfileRoute from "./ProtectedAuthAndProfileRoute";
import ErrorScreen from "../pages/errorScreens/ErrorScreen";
import HomeScreen from "../pages/homeScreens/HomeScreen";
import AddPatientScreen from "../pages/addPatientScreens/AddPatientScreen";
import InvitationsOverviewScreen from "../pages/invitationsScreens/InvitationsOverviewScreen";
import LinkedPatientCalendarScreen from "../pages/patientInsightsScreens/LinkedPatientCalendarScreen";
import ChatMessagesScreen from "../pages/patientScreens/ChatMessagesScreen";
import LinkedPatientSettingsScreen from "../pages/patientScreens/LinkedPatientSettingsScreen";
import AccountScreen from "../pages/accountScreens/AccountScreen";
import LinkedPatientInsightsScreen from "../pages/patientInsightsScreens/LinkedPatientInsightsScreen";
import NameTherapistSettingScreen from "../pages/settingScreens/NameTherapistSettingScreen";
import EmailNotVerifiedScreen from "../pages/loginScreens/EmailNotVerifiedScreen";
import SubscriptionPlanOverviewScreen from "../pages/subscriptionScreens/SubscriptionPlanOverviewScreen";
import SubscriptionBoughtScreen from "../pages/subscriptionScreens/SubscriptionBoughtScreen";
import ManageSubscriptionScreen from "../pages/subscriptionScreens/ManageSubscriptionScreen";

const AppRoutes = () => (
    <Routes>
        <Route path="/login" element={ <LoginScreen />  } />
        <Route path="/" element={  <LoginScreen/> } />
        <Route path="/homeScreen" element={<ProtectedAuthAndProfileRoute>  <HomeScreen/></ProtectedAuthAndProfileRoute> } />
        <Route path="/addPatientScreen" element={ <ProtectedAuthAndProfileRoute>  <AddPatientScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/invitationsOverviewScreen" element={ <ProtectedAuthAndProfileRoute>  <InvitationsOverviewScreen/> </ProtectedAuthAndProfileRoute>} />

        <Route path="/LinkedPatientCalendarScreen/:patientId" element={ <ProtectedAuthAndProfileRoute>  <LinkedPatientCalendarScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/linkedPatientSettingsScreen/:patientId" element={ <ProtectedAuthAndProfileRoute>  <LinkedPatientSettingsScreen/> </ProtectedAuthAndProfileRoute>} />

        <Route path="/linkedPatientInsightsScreen/:patientId/:chatId" element={ <ProtectedAuthAndProfileRoute>  <LinkedPatientInsightsScreen/> </ProtectedAuthAndProfileRoute>} />

        <Route path="/chatMessagesScreen/:patientId/:chatId" element={ <ProtectedAuthAndProfileRoute>  <ChatMessagesScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/accountScreen" element={ <ProtectedAuthAndProfileRoute>  <AccountScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/nameTherapistSettingScreen" element={ <ProtectedAuthAndProfileRoute>  <NameTherapistSettingScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/emailNotVerifiedScreen" element={<ProtectedAuthAndProfileRoute>         <EmailNotVerifiedScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/subscriptionPlanOverviewScreen" element={<ProtectedAuthAndProfileRoute>         <SubscriptionPlanOverviewScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/subscriptionBoughtScreen" element={<ProtectedAuthAndProfileRoute>         <SubscriptionBoughtScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/manageSubscriptionScreen" element={<ProtectedAuthAndProfileRoute>         <ManageSubscriptionScreen/> </ProtectedAuthAndProfileRoute>} />
        <Route path="/subscriptionBoughtScreen" element={<ProtectedAuthAndProfileRoute>         <SubscriptionBoughtScreen /> </ProtectedAuthAndProfileRoute>} />

        <Route path="*" element={<ProtectedAuthAndProfileRoute><ErrorScreen /></ProtectedAuthAndProfileRoute>} />
    </Routes>
);

export default AppRoutes;
