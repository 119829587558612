import {
  Flex, Text, Box, IconButton, Tooltip,
} from '@chakra-ui/react'
import { TypeAnimation } from 'react-type-animation';
import { MessageType, TypeSpeed } from '../../types/types';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from "../../styles/styles";
import { t} from 'i18next';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
interface MessageProps {
  message: MessageType;
  theme: Theme;
}

function Message(props: MessageProps) {
  let theme = props.theme;






  if (props.message.sender !== 'user')
    return (
      <Flex
        maxWidth="95%"
        alignItems={'center'}
      >
        <Box
          paddingLeft="10px"
          paddingRight="10px"
          backgroundColor="#f5f6f8"
          borderRadius="10px"
          paddingBottom="5px"
          paddingTop="5px"
          marginLeft="5px"
          marginBottom={"5px"}
          marginTop={"5px"}
        >
          <Text color="#000">{props.message.message}</Text>
        </Box>
       

      </Flex>
    );
  else {
    return (<Flex
      maxWidth="95%"
    >
      
      <Text
        paddingLeft="10px"
        paddingRight="10px"
        paddingBottom="5px"
        paddingTop="5px"
        backgroundColor={props.message.isSaved ? theme.completementaryColor1 : theme.messageBackgroundColor}
        borderRadius="10px"
        marginRight={"10px"}
        color={theme.messageTextColor}
      >
        {props.message.message}
      </Text>

    </Flex>)
  }

}
export default Message