
import { User } from "firebase/auth";
import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FirebaseInterface from "../services/firebase";
import { AvatarParams, TherapistProfile, TherapistSettings, UserProfile } from "../types/types";


type UserProfileContextType = {
  therapistProfile: TherapistProfile;
  setTherapistProfile: Dispatch<SetStateAction<TherapistProfile>>;
  profileFetchingComplete: boolean;
  currentUser: User | null
};



const dafaultTherapistProfile: TherapistProfile = {
  name: "not_complete",
  notifications: [],
  subscriptionId: "therapist_trial",
  patients: []
}


// Create a new context
export const UserProfileContext = createContext<UserProfileContextType>({
  therapistProfile: dafaultTherapistProfile,
  setTherapistProfile: () => { },
  profileFetchingComplete: false,
  currentUser: null
});



// Create a context provider component
export const UserProfileContextProvider = ({ children }: { children: React.ReactNode }) => {
  // Define your shared state
  const [therapistProfile, setTherapistProfile] = useState<TherapistProfile>(dafaultTherapistProfile);
  const [firebase] = useState<FirebaseInterface>(new FirebaseInterface());
  const [profileFetchingComplete, setProfileFetchingComplete] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>();



  const therapistProfileIsComplete = (therapistProfile: TherapistProfile) => {
    if (therapistProfile.name === "not_complete") {
      return false;
    }
    return true;
  }


  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        firebase.checkIfTherapistExistsInFirestore(user.uid).then((exists: boolean) => {
          if (exists) {
            firebase.fetchTherapistProfile(user.uid).then((therapistProfile: TherapistProfile) => {
              // no  more completeness check
              if (therapistProfileIsComplete(therapistProfile)) {
                setTherapistProfile(therapistProfile);
              }
             
              setProfileFetchingComplete(true)
            });
          } else {
            setProfileFetchingComplete(true)
          }
        }
        );
      }
      else {
        // user has loggged out
      }
      setCurrentUser(user);
    });

    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserProfileContext.Provider value={{ therapistProfile: therapistProfile, setTherapistProfile: setTherapistProfile, currentUser, profileFetchingComplete }}>
      {children}
    </UserProfileContext.Provider>
  );
};
