import { useContext, useEffect, useState } from 'react';
import { Alert, AlertDescription, Button, CloseButton, Flex, Text, useBreakpointValue, AlertTitle, Divider, Spinner } from '@chakra-ui/react';
import { lavenderStyle, Theme } from '../../styles/styles';
import { t } from 'i18next';
import { Link as Routerlink } from "react-router-dom";
import ProgressBar from './ProgressBar';
import { UserProfileContext } from '../../contexts/userProfileContextProvider';
import { FirebaseContext } from '../../contexts/firebaseProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPlus } from '@fortawesome/free-solid-svg-icons';
import { SubscriptionPlan } from '../../types/types';
import { largePlan, mediumPlan, smallPlan, trialPlan } from '../../data/subscriptions/subscriptionPlans';


interface UsageLimitAlertProps {
  onClose: () => void;
}

const InviteLimitExceededAlert = (props: UsageLimitAlertProps) => {
  const [theme] = useState<Theme>(lavenderStyle);
  const notificationWidth = useBreakpointValue({ base: "98%", sm: "95%", md: "700px" }); // Use useBreakpointValue hook to set different widths based on screen size
  const { therapistProfile, currentUser, setTherapistProfile } = useContext(UserProfileContext);
  const firebaseInterface = useContext(FirebaseContext);
  const [isActiveInvitesLoaded, setIsActiveInvitesLoaded] = useState<boolean>(false)
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState<SubscriptionPlan | null>(null)

  const [numberActiveInvites, setNumberActiveInvites] = useState<number>(0)

  useEffect(() => {
    if (currentUser) {
        firebaseInterface.getSubscriptionInfo(currentUser.uid).then((subscriptionInfo) => {
            if (subscriptionInfo) {
                switch (subscriptionInfo.role) {
                    case 'therapist_small':
                        setCurrentSubscriptionPlan(smallPlan)
                        break;
                    case 'therapist_medium':
                        setCurrentSubscriptionPlan(mediumPlan)
                        break;
                    case 'therapist_large':
                        setCurrentSubscriptionPlan(largePlan)
                        break;

                }
            }
            else {
                setCurrentSubscriptionPlan(trialPlan)
            }
        })


    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [currentUser])

  useEffect(() => {
    if (!currentUser) {
      return
    }
    firebaseInterface.fetchInvitations(currentUser.uid).then((invites) => {

      let nValidInvites = 0
      invites.forEach((invite) => {
        if (invite.valid) {
          nValidInvites += 1
        }
      })
      setNumberActiveInvites(nValidInvites)
      setIsActiveInvitesLoaded(true)
    }
    )


  }, [currentUser])



  if (!isActiveInvitesLoaded) {
    return <Flex
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="9999"
      backgroundColor="rgba(0, 0, 0, 0.5)"
    >

      <Spinner size='xl'></Spinner>
    </Flex>
  }
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="9999"
      backgroundColor="rgba(0, 0, 0, 0.5)"
    >
      <Alert status={'info'} variant="subtle" width={notificationWidth} bgColor={theme.fifthColor} borderRadius="20px" borderColor={theme.secondaryColor} borderWidth={'1px'}>
        <AlertDescription width="100%">
          <Flex width="100%" justifyContent={"center"} alignItems="center" flexDir={"column"}>

            <AlertTitle mb='5px'>Patiënten limiet bereikt</AlertTitle>
            <Text fontWeight={'light'}>U kan geen patiënten meer toevoegen omdat het aantal gekoppelde patiënten + het aantal openstaande uitnodiging de toegestane limiet van uw abonnement bereikt.
              Verwijder een patiënt of een uitnodiging om een nieuwe patiënt te kunnen toevoegen of upgrade uw abonnement.
            </Text>
            <Divider mt='15px' borderColor={theme.thirdColor} mb='15px'></Divider>
            <Flex mb='10px'>
              <Text fontWeight={'bold'} color={theme.primaryColor} mr='1'>Gekoppelde patiënten:</Text>
              <Text>{therapistProfile.patients.length}</Text>
            </Flex>
            <Flex mb='10px'>
              <Text fontWeight={'bold'} color={theme.primaryColor} mr='1'>Openstaande uitnodiging:</Text>
              <Text>{numberActiveInvites}</Text>
            </Flex>
            <Text mb='10px' fontWeight={'bold'}  > Limiet patiënten + uitnodiging  </Text>
            <Flex borderWidth={'1px'} borderRadius='md' borderColor={theme.secondaryColor} pl='2' pr='2'>


              <ProgressBar current={therapistProfile.patients.length + numberActiveInvites} total={currentSubscriptionPlan? currentSubscriptionPlan.limitTotalPatients:1} ></ProgressBar>
            </Flex>
            <Divider mt='15px' borderColor={theme.thirdColor} mb='15px'></Divider>

            <Flex width={'100%'} flexDir='column' rowGap={'10px'}>

            <Button    variant={'outline'}      width={'100%'} leftIcon={<FontAwesomeIcon icon={faEnvelope} />} as={Routerlink} to='/invitationsOverviewScreen' colorScheme={theme.colorScheme}> Bekijk openstaande uitnodigingen</Button>
            <Button      width={'100%'} leftIcon={<FontAwesomeIcon icon={faPlus} />} as={Routerlink} to='/manageSubscriptionScreen' colorScheme={theme.colorScheme}>     Upgrade abonnement</Button>

            </Flex>

          </Flex>

        </AlertDescription>
        <CloseButton color={theme.primaryColor} onClick={props.onClose} position="absolute" right="8px" top="8px" />
      </Alert>
    </Flex>
  );
};

export default InviteLimitExceededAlert
  ;
