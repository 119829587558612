import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Divider,
    Flex, Heading, IconButton, Input, Text, Textarea
} from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";


import { Link as Routerlink, } from "react-router-dom";
import { FirebaseContext } from "../../contexts/firebaseProvider";
import { UserProfileContext } from "../../contexts/userProfileContextProvider";
import { t } from "i18next";
import { CloseIcon } from "@chakra-ui/icons";
import { PatientInvite } from "../../types/types";
import PreviousScreenButton from "../../components/sharedComponents/navigation/PreviousScreenButton";
import TopNavigationRow from "../../components/sharedComponents/topNavigationRow/TopNavigationRow";
function AddPatientScreen() {
    const [theme] = useState<Theme>(lavenderStyle)
    const firebaseInterface = useContext(FirebaseContext);
    const [patientSignUpIdToken, setPatientSignUpIdToken] = useState<string>("")
    const { therapistProfile: userProfile, currentUser } = useContext(UserProfileContext);
    const [isShowCodeStep, setIsShowCodeStep] = useState<boolean>(false)
    const [nameTooLongError, setNameTooLongError] = useState(false);
    const [patientName, setPatientName] = useState("");
    const [description, setDescription] = useState<string>('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [isInvitingPatient, setIsInvitingPatient] = useState<boolean>(false)

    const [showNameNotFilledError, setShowNameNotFilledError] = useState<boolean>(false)


    const checkIfFormIsComplete = () => {
        if (patientName === "") {
            setShowNameNotFilledError(true)
            return false
        }
        else {
            setShowNameNotFilledError(false)
            return true
        }
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
        adjustTextareaHeight();
    };

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            adjustTextareaHeight();
        }
    }, []);



    const handleNameChange = (event: any) => {
        const input = event.target.value;

        if (input.length <= 40) {
            setPatientName(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))

            setNameTooLongError(false);
            setShowNameNotFilledError(false)
        } else {
            setNameTooLongError(true);
        }
    };





    const isAllowedToAddPatient = async () => {
        if (!currentUser) {
            return false

        }

        //const nTokens = await firebaseInterface.countSignUpTokens(currentUser.uid)
        //const countInvites = await firebaseInterface.countInvites(currentUser.uid)

        //if (nTokens >= 5) {
        //    return false
        //}
        return true
    }


    const generatePatientSignUpIdToken = async () => {
        if (!currentUser) {
            throw new Error("no current user")
        }
        // get patient sign up id token
        let token = ""
        // generate 3 random letters and 3 random numbers
        const letters = "abcdefghijklmnopqrstuvwxyz"

        for (let i = 0; i < 3; i++) {
            token += letters.charAt(Math.floor(Math.random() * letters.length))

        }
        token += "-"
        for (let i = 0; i < 3; i++) {
            token += Math.floor(Math.random() * 10)
        }
        console.log(token)

        let exists = await checkIfTokenExists(currentUser.uid, token)
        if (exists) {
            console.log("token exists")
        }
        return token
    }

    const checkIfTokenExists = async (therapistId: string, idToken: string) => {
        return await firebaseInterface.checkIfClientSignUpTokenExists(therapistId, idToken)
    }



    const invitePatient = async () => {

        if (!currentUser) { return }
        if (!checkIfFormIsComplete()) {

            return

        }
        setIsInvitingPatient(true)
        if (await isAllowedToAddPatient()) {
            const token = await generatePatientSignUpIdToken()

            const invitation: PatientInvite = {
                name: patientName,
                description: description,
                tokenId: token,
                valid: true,
                timeStamp: firebaseInterface.getServerTimestamp()
            }

            const success = await firebaseInterface.invitePatient(currentUser.uid, invitation)
            setPatientSignUpIdToken(token)
            setIsShowCodeStep(true)
        }
        setIsInvitingPatient(false)

    }



    return (
        <Flex>
            {!isShowCodeStep &&
                <Flex flexDirection='column' maxHeight="97svh" height='100vh' width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
                    <TopNavigationRow />
                    <Flex width="90%" justifyContent={"flex-start"}    >
                        <PreviousScreenButton previousScreen={`/homeScreen`} />
                    </Flex>
                    <Flex width={"100%"} justifyContent="center" mt='-40px'>
                        <Heading size='lg' paddingTop='5px' marginLeft="5px">
                            Nodig patiënt uit
                        </Heading>
                    </Flex>
                    <Flex width={'100%'} justifyContent='center' >
                        <Flex flexDir={'column'}  >



                            <Flex width={'100%'} flexDir='column' mt='20px' mb='10px' >
                                <Text mb='5px' fontWeight={'bold'} >Naam: </Text>
                                <Input maxWidth={"300px"} placeholder={"Vul naam patiënt in"}
                                    _placeholder={{ opacity: 0.6, color: 'inherit' }} type="text" value={patientName} onChange={handleNameChange} isRequired />
                                <Flex width={'100%'} justifyContent='center' mt='5px'>
                                    {!nameTooLongError ? (
                                        <Flex></Flex>
                                    ) : (
                                        <Text fontSize="sm" >  Naam te lang </Text>)}
                                    {showNameNotFilledError ? (
                                        <Text color='red.600' fontSize="sm" >  Naam niet ingevuld </Text>) : (<Flex></Flex>)}
                                </Flex>

                            </Flex>



                            <Box
                                borderRadius="md"
                                width="100%"
                                mb="5"
                            >
                                <Text fontWeight={'bold'} mb='5px'>(Optioneel) Opmerkingen over patiënt:</Text>

                                <Textarea
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    placeholder="Voer een beschrijving in"
                                    aria-label="Description input"
                                    resize="none"
                                    overflow="hidden"
                                    minH="20px" // Adjusted the minimum height to 1 row (can be adjusted based on your desired row height)
                                    maxWidth={"300px"}
                                    _focus={{
                                        outline: 'none',
                                        borderColor: theme.thirdColor,
                                        boxShadow: '0 0 0 2px ' + theme.thirdColor,
                                    }}
                                    ref={textareaRef}
                                />

                            </Box>

                        </Flex>
                    </Flex>

                    <Flex flexDir="column" mt="5" maxWidth={"900px"} rowGap='20px' justifyContent="center" alignItems={"center"}>
                        <Button isLoading={isInvitingPatient} onClick={() => { invitePatient() }} variant={'outline'} colorScheme={theme.colorScheme}> Nodig uit</Button>


                    </Flex>

                </Flex>
            }


            {isShowCodeStep &&
                <Flex flexDirection='column' maxHeight="97svh" height='100vh' width="96%" alignItems="center" marginLeft="auto" marginRight="auto" marginBottom="20px" >
                    <TopNavigationRow />
                    <Flex width={"100%"} justifyContent="center">
                        <Heading size='lg' paddingTop='5px' marginLeft="5px">
                            Nodig patiënt uit
                        </Heading>
                    </Flex>

                    <Text mt='20px'>Met de volgende code kan {patientName} zich met jou linken:</Text>

                    <Flex mt='20px' flexDir='row' borderColor='blackAlpha.400' borderWidth={'1px'} borderRadius='10px' p='2'>

                        <Text fontSize={'lg'} fontWeight={'bold'}>{patientSignUpIdToken}</Text>


                    </Flex>


                    <Divider maxWidth={'500px'} mt='20px' mb='20px' borderColor={theme.thirdColor}></Divider>
                    <Flex flexDir="column" maxWidth={"900px"} rowGap='20px' justifyContent="center" alignItems={"center"}>
                        <Button as={Routerlink} to='/invitationsOverviewScreen' colorScheme={theme.colorScheme}> Ga terug</Button>


                    </Flex>

                </Flex>
            }
        </Flex>

    );
}



export default AddPatientScreen